import React from "react";
import { useParams } from "react-router-dom";
import { useBlogs } from "../js/context/BlogProvider";
import BackButton from "../components/BackButton";
import Button from "../components/Button";
import BlogList from "../components/BlogList";
import NotFound from "../components/NotFound";
import SocialShare from "../components/SocialShare";

const BlogSingle = () => {
  const { url } = useParams();
  const { blogs, error, isLoading } = useBlogs();

  if (isLoading) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen gap-6 px-5 text-center">
        <div className="flex flex-col max-w-sm">
          <p className="text-xl leading-tight">Loading...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <NotFound
        title="Error Loading Blogs"
        message="An error occurred while loading the blogs"
        buttonText="Go back to the homepage"
        buttonLink="/"
      />
    );
  }

  const blog = blogs.find((blog) => blog.url === url);

  if (!blog) {
    return (
      <NotFound
        title="Blog Not Found"
        message="The page you are looking for could not be found"
        buttonText="Go back to the homepage"
        buttonLink="/"
      />
    );
  }

  return (
    <div className="min-h-screen pb-12 bg-white blogSingle">
      <div
        className="relative flex items-start justify-center w-full mx-auto"
        style={{
          backgroundImage: `url(${blog?.image?.url})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "60vh",
        }}
      >
        <div
          className="absolute inset-0"
          style={{
            background:
              "linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.1), white)",
          }}
        ></div>
        <div className="absolute z-50 w-full px-5 top-4 md:px-6 lg:px-8">
          <div className="max-w-screen-xl mx-auto">
            <BackButton className="-ml-1 text-black" />
          </div>
        </div>
      </div>
      <div className="relative z-50 px-5 -mt-12 md:px-6 lg:px-8">
        <div className="max-w-screen-xl mx-auto">
          <div className="grid grid-cols-1 gap-8 pt-4 sm:grid-cols-[auto,320px] md:grid-cols-[auto,350px]">
            <div className="">
              <h1 className="max-w-screen-md mb-8 text-5xl font-extrabold md:text-6xl font-condensed">
                {blog.title}
              </h1>
              <p
                className="max-w-screen-md text-black rich-text"
                dangerouslySetInnerHTML={{ __html: blog.content }}
              ></p>
              <SocialShare />
            </div>
            <div className="">
              <div className="sticky px-5 py-6 bg-gradient-to-br from-secondary-700 to-secondary-950 rounded-lg shadow-lg top-24">
                <h2
                  className="text-3xl font-bold leading-tight font-condensed"
                  style={{ color: "white" }}
                >
                  Experience Premium Training
                </h2>
                <p className="mt-2 text-base leading-normal text-secondary-100">
                  Elevate your training with intelligent guidance that evolves
                  with your progress. From perfect form to personalized plans,
                  unlock your full potential with premium features designed for
                  real results.
                </p>
                <p className="mt-3 text-xl font-semibold text-white">
                  Premium features include:
                </p>
                <ul className="text-lg list-disc list-inside text-secondary-100">
                  <li className="ml-1 mt-2 text-base leading-snug">
                    Smart Personalized Training
                  </li>
                  <li className="ml-1 mt-1 text-base leading-snug">
                    Premium Meal Plans & Recipes
                  </li>
                  <li className="ml-1 mt-1 text-base leading-snug">
                    Complete Workout Library
                  </li>
                  <li className="ml-1 mt-1 text-base leading-snug">
                    Beautiful Progress Analytics
                  </li>
                  <li className="ml-1 mt-1 text-base leading-snug">
                    Pure Experience, Zero Ads
                  </li>
                </ul>
                <a
                  href="https://app.stridez.io/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button animation={true} className="w-full mt-4 uppercase">
                    Start Your 10-Day Free
                  </Button>
                </a>
                <p className="mt-1.5 text-sm leading-snug text-center text-secondary-200">
                  92% of users continue after their trial
                </p>
              </div>
            </div>
          </div>
          <BlogList
            blogs={blogs}
            error={error}
            isLoading={isLoading}
            className="mt-8 md:mt-16"
            filterUrl={url}
            limit={3}
            heading="Blog"
            subheading="Explore our latest articles"
          />
        </div>
      </div>
    </div>
  );
};

export default BlogSingle;
