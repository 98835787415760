import React from "react";
import BackButton from "../../components/BackButton";

const Community = () => {
  return (
    <div className="w-full max-w-screen-md px-4 pt-4 pb-12 rich-text mx-auto text-black bg-white md:pt-8 md:px-6 lg:px-8 xl:px-4 docs">
      <BackButton />
      <h1 className="mb-6 text-3xl text-black font-bold text-center">
        COMMUNITY GUIDELINES
      </h1>

      <p className="mb-4">
        Last updated on <strong>March 19, 2024</strong> (
        <strong>"Effective Date"</strong>).
      </p>

      <h2 className="mb-4 text-2xl font-bold">INTRODUCTION</h2>

      <p className="mb-4 text-justify">
        Welcome to the Stridez Community! At Stridez, we are thrilled to have
        you join us in a shared journey towards better health and fitness. Our
        community, accessible through the Stridez App, website, and associated
        platforms, including our dedicated fitness Discord server, is designed
        to be a supportive, informative, and motivating space where individuals
        can come together to share their experiences, challenges, and successes.
      </p>

      <p className="mb-4 text-justify">
        Our <strong>Community Guidelines</strong> are here to ensure that every
        member feels welcome, respected, and encouraged in a safe and positive
        environment. These guidelines apply to all forms of interaction within
        our community, including posts, comments, messages, and any other form
        of communication on our platforms.
      </p>

      <p className="mb-4 text-justify">
        By participating in the Stridez Community, you agree to these
        guidelines, which are in place to foster constructive and respectful
        interactions. We are committed to maintaining a community where users
        can inspire each other, learn, and progress towards their fitness goals
        together. Let's work towards creating a positive impact on each other's
        lives, sharing knowledge, and building a community that celebrates every
        step of the fitness journey.
      </p>

      <p className="mb-4 text-justify">
        Remember, your contribution to the community can make a significant
        difference in how others perceive and engage with their health and
        fitness goals. We encourage you to read these guidelines carefully and
        uphold them in all your interactions within the Stridez Community.
      </p>

      <p className="mb-4 text-justify">
        These guidelines serve as our acceptable use policy, and we urge you to
        report any incident that violates them to us via{" "}
        <a
          href="mailto:support@stridez.io"
          className="text-blue-600 hover:underline"
        >
          support@stridez.io
        </a>
        .
      </p>

      <p className="mb-6">
        We recommend that you also consult our <strong>Terms of Service</strong>{" "}
        for additional information on terms applicable to you when you use our
        App or website or our services.
      </p>

      <ol className="pl-6 space-y-4 list-decimal">
        <li>
          <strong>Respect and Inclusivity:</strong> Every member of the Stridez
          Community is expected to treat others with respect and courtesy.
          Personal attacks, harassment, bullying, and derogatory comments will
          not be tolerated. We encourage constructive criticism and debate but
          insist that it be done in a manner that respects the dignity of all
          participants.
        </li>
        <li>
          <strong>Gamification and Leaderboards:</strong> Participate in
          challenges and leaderboards in a spirit of friendly competition. Do
          not attempt to manipulate rankings or falsify your progress. Remember
          that the purpose of these features is to motivate and inspire, not to
          create division or discourage others.
        </li>
        <li>
          <strong>Privacy and Confidentiality:</strong> Respect the privacy and
          confidentiality of others. Do not share personal or sensitive
          information without consent.
        </li>
        <li>
          <strong>No Misinformation:</strong> Ensure that any health or fitness
          advice shared is based on credible sources. Misinformation can lead to
          harm, and perpetuating unverified claims is prohibited.
        </li>
      </ol>

      <p className="my-4 text-justify">
        While our AI-generated workout and meal plans are designed to be
        helpful, they should not be considered as professional medical advice.
        Always consult with healthcare professionals for personalized medical
        guidance.
      </p>

      <ol start="5" className="pl-6 space-y-4 list-decimal">
        <li>
          <strong>No Promotion of Harmful Behavior:</strong> Do not encourage or
          glorify self-harm, eating disorders, or any behavior detrimental to
          health. Our community supports wellness and healthy lifestyles.
        </li>
        <li>
          <strong>Respect Copyrights and Creativity:</strong> Honor the
          creativity and rights of others by not posting copyrighted content
          without permission. This includes text, images, videos, and music.
        </li>
        <li>
          <strong>Keep Personal Disputes Private:</strong> Resolve personal
          disputes privately and not within public community spaces. Public
          arguments and airing personal grievances disrupt the community
          environment.
        </li>
        <li>
          <strong>No Impersonation:</strong> Impersonating another user, public
          figure, or Stridez representative undermines trust and is strictly
          forbidden.
        </li>
        <li>
          <strong>Transparency in Affiliations:</strong> If you have a
          professional affiliation with a product or service you're
          recommending, disclose this information. Transparency builds trust.
        </li>
        <li>
          <strong>Responsible Content Sharing:</strong> Be mindful of the
          content you share; overly graphic or disturbing content, even within
          the context of health and fitness, should be shared with caution and
          appropriate warnings.
        </li>
        <li>
          <strong>Adherence to Legal Compliance:</strong> Follow all applicable
          laws and regulations in your jurisdiction, especially those related to
          health, privacy, and online conduct.
        </li>
        <li>
          <strong>No Financial Scams:</strong> Do not engage in scams,
          fraudulent schemes, or attempts to solicit money under deceptive
          pretenses. Our community is based on trust and support.
        </li>
        <li>
          <strong>Language and Profanity:</strong> Use language that is
          appropriate for a diverse audience. Excessive profanity or vulgar
          language may alienate members and is discouraged.
        </li>
        <li>
          <strong>Protecting User Safety:</strong> Do not post or share content
          that threatens the safety of others, including physical threats,
          doxxing, or intimidation.
        </li>
        <li>
          <strong>No Unauthorized Commercial Activity:</strong> Commercial
          activities, like selling goods or services and soliciting for
          donations, are not allowed without prior approval from the moderators.
        </li>
        <li>
          <strong>Age-Appropriate Content:</strong> Content shared in the
          community should be appropriate for individuals of all ages,
          reflecting the diverse age range of our members.
        </li>
        <li>
          <strong>No Political or Religious Proselytizing:</strong> While
          respectful discussions are welcome, our community is not a platform
          for political debates or religious proselytizing. Focus discussions on
          health and fitness.
        </li>
        <li>
          <strong>Inclusive Environment:</strong> Foster an inclusive
          environment where all members feel welcome and valued regardless of
          their background, identity, or beliefs. Avoid discriminatory behavior
          or language that excludes or marginalizes others.
        </li>
        <li>
          <strong>Constructive Communication:</strong> Engage in constructive
          communication and discussions. Share your thoughts and experiences in
          a thoughtful and considerate manner, promoting meaningful interactions
          and mutual understanding.
        </li>
        <li>
          <strong>Positive Support:</strong> Offer support and encouragement to
          fellow members in their fitness journeys. Celebrate achievements,
          provide motivation, and offer helpful advice or tips to contribute
          positively to the community's atmosphere.
        </li>
        <li>
          <strong>Safety First:</strong> Prioritize the safety and well-being of
          all community members. Refrain from sharing harmful or misleading
          information that could jeopardize someone's health or safety.
        </li>
        <li>
          <strong>Respect for Diversity:</strong> Embrace the diversity of our
          community. Members come from various backgrounds, each bringing unique
          perspectives. Discrimination or intolerance based on cultural,
          geographic, or personal differences is unacceptable.
        </li>
        <li>
          <strong>Constructive Feedback:</strong> Offer feedback in a
          constructive manner. Critiques should aim to support and uplift, not
          belittle. Always consider the impact of your words on the recipient's
          progress and motivation.
        </li>
        <li>
          <strong>Compliance with Terms:</strong> Adhere to the Stridez Terms of
          Service. Familiarize yourself with and follow it to ensure that you
          meet platform requirements.
        </li>
        <li>
          <strong>Non-Discrimination:</strong> Our platform is open to all, and
          discrimination based on race, religion, gender, nationality,
          disability, sexual orientation, or any other protected category will
          not be tolerated.
        </li>
      </ol>

      <h2 className="mt-8 mb-4 text-2xl font-bold">
        Consequences of Violations
      </h2>

      <p className="mb-4">
        Violations of these Community Guidelines may result in actions,
        including:
      </p>

      <ul className="pl-6 mb-6 list-disc">
        <li>Temporary suspension of your account.</li>
        <li>Permanent termination of your account.</li>
        <li>Legal action if your actions violate applicable laws.</li>
        <li>
          Cooperation with law enforcement in cases of illegal activities.
        </li>
      </ul>

      <p className="mb-6">
        We reserve the right to take appropriate actions based on the severity
        and frequency of violations.
      </p>

      <ol start="22" className="pl-6 space-y-4 list-decimal">
        <li>
          <strong>Reporting Violations:</strong> If you come across content or
          behavior that violates these Community Guidelines or Stridez policies,
          report it promptly to Stridez at{" "}
          <a
            href="mailto:support@stridez.io"
            className="text-blue-600 hover:underline"
          >
            support@stridez.io
          </a>
          . Your reports help us maintain a safe and respectful community.
        </li>
        <li>
          <strong>Changes to Guidelines:</strong> The Stridez Community
          Guidelines are dynamically updated to reflect the changing needs of
          our community, legal landscapes, and operational practices. These
          updates are made with the intention of ensuring a safe, respectful,
          and engaging environment for all users. It's important to note that
          updates to the guidelines will become effective upon posting on the
          Stridez App or website. We encourage our community members to
          regularly review the guidelines to stay informed of any modifications.
          Your continued use of the community spaces after such updates
          signifies your agreement to the revised guidelines. We believe these
          practices are essential for maintaining the integrity and safety of
          our community, and we appreciate your understanding and adherence to
          these terms.
        </li>
      </ol>

      <h2 className="mt-8 mb-4 text-2xl font-bold">Contacting Us</h2>

      <p className="mb-4">
        If you have any questions about these{" "}
        <strong>Community Guidelines</strong>, or if you would like to report a
        violation, please contact us at{" "}
        <a
          href="mailto:support@stridez.io"
          className="text-blue-600 hover:underline"
        >
          support@stridez.io
        </a>
        .
      </p>

      <p className="mb-4">Thank you,</p>

      <p className="font-bold">Team Stridez</p>
    </div>
  );
};

export default Community;
