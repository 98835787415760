import React, { useState, useRef, useEffect } from "react";
import Hero from "../components/Hero";
import MainShell from "../components/MainShell";
import SectionHeader from "../components/SectionHeader";
import Button from "../components/Button";
import newsletterBg from "../images/newsletter.webp";
import phone1 from "../images/phone_01.webp";
import phone2 from "../images/phone_02.webp";
import phone3 from "../images/phone_03.webp";
import phone4 from "../images/phone_04.webp";
import { ReactComponent as AppStore } from "../icons/AppStore.svg";
import { ReactComponent as GooglePlay } from "../icons/GooglePlay.svg";
import { ReactComponent as MacroIcon } from "../icons/macro.svg";
import { ReactComponent as LibraryIcon } from "../icons/library.svg";
import { ReactComponent as AnalyticsIcon } from "../icons/analytics.svg";
import { ReactComponent as StreakIcon } from "../icons/streak.svg";
import { ReactComponent as ChevronIcon } from "../icons/chevron-down.svg";
import { ReactComponent as MovementsIcon } from "../icons/movements.svg";
import { XANO_BASE } from "../config";
import { scrollTo } from "../js/utils";
import CachedImage from "../components/CachedImage";
import BlogList from "../components/BlogList";
import { useBlogs } from "../js/context/BlogProvider";
import { ReactComponent as StarsIcon } from "../icons/stars.svg";
import { useInView } from '../hooks/useInView';
import { motion } from 'framer-motion';
import { useMultipleInView } from '../hooks/useMultipleInView';

const Home = () => {
  const [openItemIds, setOpenItemIds] = useState([]);
  const [email, setEmail] = useState("");
  const [touched, setTouched] = useState(false);
  const { blogs, isLoading, error } = useBlogs();
  const [showAllFaqs, setShowAllFaqs] = useState(false);
  const [featuresRef, featuresInView] = useInView({
    threshold: 0.2,
    triggerOnce: true
  });
  const [testimonialsRef, testimonialsInView] = useInView({ threshold: 0.2, triggerOnce: true });
  const [faqsRef, faqsInView] = useInView({ threshold: 0.2, triggerOnce: true });
  const experienceRefs = useRef([]);
  const inViewExperienceItems = useMultipleInView(experienceRefs, {
    threshold: 0.2,
    triggerOnce: true
  });

  const prefersReducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)').matches;

  const faqs = [
    {
      question: "How does Stridez elevate my fitness journey?",
      answer:
        "Stridez transforms your everyday movements into meaningful progress. Your workouts evolve as you do, creating a personalized path to your goals. From thoughtfully crafted exercises to celebrating daily victories, every interaction is designed to inspire your next achievement.",
    },
    {
      question: "What makes the Stridez experience uniquely engaging?",
      answer:
        "We've reimagined fitness tracking as a rewarding journey. Each workout builds your streak, every milestone unlocks new experiences, and our vibrant community celebrates progress together. It's not just about logging activities – it's about making every step forward feel extraordinary.",
    },
    {
      question: "Can Stridez adapt to my changing fitness needs?",
      answer:
        "Whether you're starting your first workout or perfecting your form, Stridez grows with you. Our carefully curated collection of movements and programs seamlessly adjusts to your pace and preferences. Your journey is unique, and your experience should be too.",
    },
    {
      question: "How does Stridez help me understand my progress?",
      answer:
        "Discover the story of your fitness journey through beautifully crafted insights. Watch your achievements unfold in elegant visualizations, spot emerging patterns, and receive thoughtfully timed recommendations. It's your progress, presented with clarity and purpose.",
    },
    {
      question: "Tell me about Stridez's commitment to privacy.",
      answer:
        "Your trust is essential to us. We've crafted a secure environment where your personal journey remains exactly that – personal. Every aspect of Stridez is built with your privacy at its core, ensuring peace of mind while you focus on what matters most: your progress.",
    },
    {
      question: "How does Stridez maintain its premium experience?",
      answer:
        "Excellence is in the details. We continuously refine every aspect of Stridez, from movement animations to interface interactions. Our dedication to craftsmanship ensures you always have the tools to push forward, delivered through an experience that delights.",
    },
    {
      question: "What makes the Stridez nutrition system special?",
      answer:
        "We've simplified nutrition without sacrificing sophistication. Browse our curated collection of premium recipes, track your meals with intuitive precision, and discover patterns that fuel your progress. It's nutrition tracking reimagined for the way you live.",
    },
    {
      question: "How accessible is support with Stridez?",
      answer:
        "Behind every great journey is a dedicated support team. Our specialists are committed to ensuring your experience remains seamless, responding with care and expertise when you need it most. You focus on the journey; we'll handle the rest.",
    },
  ];
  const features = [
    {
      icon: <StarsIcon />,
      title: "Intelligent Training",
      supportText: "Experience workouts that adapt to your performance.",
    },
    {
      icon: <StreakIcon />,
      title: "Rewarding Journey",
      supportText:
        "Transform every workout into an achievement. Conquer challenges and earn rewards.",
    },
    {
      icon: <LibraryIcon />,
      title: "Curated Nutrition",
      supportText:
        "Access our entire premium recipe collection. Thoughtfully crafted, forever free.",
    },
    {
      icon: <MacroIcon />,
      title: "Magical Tracking",
      supportText:
        "Experience the joy of effortless nutrition tracking. Perfectly simple, incredibly powerful."
    },
    {
      icon: <AnalyticsIcon />,
      title: "Smart Insights",
      supportText:
        "Track progress, identify trends, and get actionable recommendations to improve.",
    },
    {
      icon: <MovementsIcon />,
      title: "Elite Movements",
      supportText:
        "Train with precision using 190+ masterfully animated exercises.",
    },
  ];

  const experienceItems = [
    {
      title: "Refined Precision",
      description:
        "A seamless workout experience that flows with you. Clean, intuitive design that keeps you focused on your training.",
      image: phone1,
    },
    {
      title: "Workouts That Fit",
      description:
        "Discover curated workout plans or create your own. Beautifully designed, infinitely customizable.",
      image: phone4,
    },
    {
      title: "Magical Tracking",
      description:
      "Speak, snap, or type to track your nutrition with delightful ease. Effortlessly precise, beautifully simple.",
      image: phone2,
    },
    {
      title: "Beautiful Insights",
      description:
        "Watch your journey unfold through elegantly crafted visualizations that tell the story of your progress.",
      image: phone3,
    },
  ];

  const testimonials = [
    {
      quote:
        "Finally, a fitness app that matches my standards. The attention to detail is extraordinary.",
      author: "Michael K.",
      title: "Professional Trainer",
    },
    {
      quote: "It's transformed how I approach fitness. Everything just flows.",
      author: "Sarah L.",
      title: "Fitness Enthusiast",
    },
  ];

  const submitNewsletter = (e) => {
    e.preventDefault();
    fetch(`${XANO_BASE}/newsletter`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email }),
    })
      .then((res) => res.json())
      .then(() => {
        setEmail("");
        setTouched(false);
      });
  };

  const handleChevron = (id) => {
    setOpenItemIds(
      openItemIds.includes(id)
        ? openItemIds.filter((itemId) => itemId !== id)
        : [...openItemIds, id]
    );
  };

  const visibleFaqs = showAllFaqs ? faqs : faqs.slice(0, 5);

  const [journeyRef, journeyInView] = useInView({ 
    threshold: 0.1,
    triggerOnce: true,
    rootMargin: '0px'
  });
  const [newsletterRef, newsletterInView] = useInView({ 
    threshold: 0.1,
    triggerOnce: true,
    rootMargin: '0px'
  });

  useEffect(() => {
    if (journeyInView) {
      console.log('Journey section in view');
    }
  }, [journeyInView]);

  useEffect(() => {
    if (newsletterInView) {
      console.log('Newsletter section in view');
    }
  }, [newsletterInView]);

  return (
    <div className="overflow-x-hidden">
      <Hero />
      <MainShell>
        <div
          className="flex flex-col gap-12 py-24 md:py-48 md:gap-16"
          id="products"
        >
          <div>
            <SectionHeader
              heading="Dive into our world"
              subheading="Elevate your fitness journey with our versatile tools and unlock your potential — one stride at a time."
            />
            <div className="grid grid-cols-1 gap-12 py-12 xs:grid-cols-2 md:grid-cols-3" ref={featuresRef}>
              {features.map((feature, index) => (
                <motion.div
                  key={index}
                  initial={prefersReducedMotion ? { opacity: 1, x: 0 } : { opacity: 0, x: -20 }}
                  animate={prefersReducedMotion ? {} : featuresInView ? { opacity: 1, x: 0 } : {}}
                  transition={{
                    duration: 0.5,
                    ease: "easeOut"
                  }}
                  className="flex flex-col gap-4"
                >
                  <div className="text-white size-10">{feature.icon}</div>
                  <div className="flex flex-col gap-2">
                    <h3 className="text-2xl font-extrabold leading-tight xs:text-3xl font-condensed">
                      {feature.title}
                    </h3>
                    <div className="text-lg leading-7 text-secondary-300">
                      {feature.supportText}
                    </div>
                  </div>
                </motion.div>
              ))}
            </div>
          </div>
        </div>
      </MainShell>

      <div className="relative pt-14 pb-32 w-full px-6">
        {/* Decorative Blobs */}
        <div className="absolute inset-0 overflow-hidden pointer-events-none">
          <div
            className="absolute w-[600px] h-[600px] rounded-full opacity-30 blur-[100px]"
            style={{
              background: "linear-gradient(180deg, #FF3366 0%, #FF6B3D 100%)",
              top: "10%",
              left: "-20%",
            }}
          />
          <div
            className="absolute w-[600px] h-[600px] rounded-full opacity-20 blur-[120px]"
            style={{
              background: "linear-gradient(180deg, #3366FF 0%, #33CCFF 100%)",
              top: "40%",
              right: "-10%",
            }}
          />
          <div
            className="absolute w-[600px] h-[600px] rounded-full opacity-25 blur-[150px]"
            style={{
              background: "linear-gradient(180deg, #9933FF 0%, #FF33CC 100%)",
              bottom: "5%",
              left: "-15%",
            }}
          />
        </div>

        <div className="max-w-screen-xl mx-auto">
          {/* <SectionHeader
            heading="Crafted for Excellence"
            subheading="Experience a fitness platform designed with intention, where every detail enhances your journey."
          /> */}
          <div className="flex flex-col gap-32 relative z-10">
            {experienceItems.map((item, index) => (
              <div
                key={index}
                ref={el => experienceRefs.current[index] = el}
                className="grid items-center grid-cols-1 gap-16 text-left md:gap-24 sm:grid-cols-2"
              >
                <div
                  className={`flex flex-col justify-center text-center md:justify-start md:text-left items-center md:items-start ${
                    index % 2 === 1 ? "sm:order-2" : ""
                  }`}
                >
                  <div className="flex flex-col gap-6">
                    <motion.h3
                      initial={prefersReducedMotion ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
                      animate={prefersReducedMotion ? {} : inViewExperienceItems.includes(index) ? { opacity: 1, y: 0 } : {}}
                      transition={{
                        duration: 0.6,
                        ease: "easeOut"
                      }}
                      className="text-5xl md:text-6xl md:leading-tight leading-tight font-extrabold font-condensed will-change-transform"
                    >
                      {item.title}
                    </motion.h3>
                    <motion.p
                      initial={prefersReducedMotion ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
                      animate={prefersReducedMotion ? {} : inViewExperienceItems.includes(index) ? { opacity: 1, y: 0 } : {}}
                      transition={{
                        duration: 0.6,
                        ease: "easeOut"
                      }}
                      className="max-w-sm text-lg font-medium md:text-xl text-secondary-300 will-change-transform"
                    >
                      {item.description}
                    </motion.p>
                  </div>
                  <motion.button
                    initial={prefersReducedMotion ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
                    animate={prefersReducedMotion ? {} : inViewExperienceItems.includes(index) ? { opacity: 1, y: 0 } : {}}
                    transition={{
                      duration: 0.5,
                      ease: "easeOut"
                    }}
                    className="mt-8 bg-primary text-black px-6 py-4 rounded-full w-64 font-bold uppercase"
                    onClick={() => scrollTo()}
                  >
                    Try it out
                  </motion.button>
                </div>
                <motion.div
                  initial={prefersReducedMotion ? { opacity: 1, x: 0 } : { opacity: 0, x: index % 2 === 0 ? 20 : -20 }}
                  animate={prefersReducedMotion ? {} : inViewExperienceItems.includes(index) ? { opacity: 1, x: 0 } : {}}
                  transition={{
                    duration: 0.6,
                    ease: "easeOut"
                  }}
                  className="will-change-transform"
                >
                  <CachedImage
                    src={item.image}
                    alt={item.title}
                    className="w-full max-w-md h-[600px] px-4 mx-auto object-contain"
                  />
                </motion.div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="py-12 md:py-24 px-6 relative w-full">
        {/* Decorative Blobs */}
        <div className="absolute inset-0 z-0 overflow-hidden pointer-events-none">
          <div
            className="absolute w-[600px] h-[600px] rounded-full opacity-20 blur-[120px]"
            style={{
              background: "linear-gradient(180deg, #FF3366 0%, #FF6B3D 100%)",
              top: "20%",
              right: "-10%",
            }}
          />
        </div>

        <div className="max-w-screen-xl mx-auto z-30 relative">
          <SectionHeader
            heading="Join the Movement"
            subheading="See what our community has to say about their transformation journey with Stridez."
          />

          <div className="grid grid-cols-1 gap-8 py-12 sm:grid-cols-2 relative z-10" ref={testimonialsRef}>
            {testimonials.map((testimonial, index) => (
              <motion.div
                key={index}
                initial={prefersReducedMotion ? { opacity: 1, scale: 0.95 } : { opacity: 0, scale: 0.95 }}
                animate={prefersReducedMotion ? {} : testimonialsInView ? { opacity: 1, scale: 1 } : {}}
                transition={{
                  duration: 0.5,
                  ease: "easeOut"
                }}
                className="flex flex-col gap-6 p-8 rounded-2xl justify-between bg-secondary-950/75 backdrop-blur-sm border border-secondary-700"
              >
                <div className="flex gap-4">
                  <div className="text-5xl text-primary">"</div>
                  <p className="text-xl md:text-2xl font-medium leading-relaxed text-secondary-200">
                    {testimonial.quote}
                  </p>
                </div>
                <div className="flex flex-col">
                  <span className="text-lg font-bold text-white">
                    {testimonial.author}
                  </span>
                  <span className="text-secondary-300">
                    {testimonial.title}
                  </span>
                </div>
              </motion.div>
            ))}
          </div>
        </div>

        <div className="pt-32 md:pt-52 max-w-screen-xl z-30 relative mx-auto">
          <BlogList
            blogs={blogs}
            error={error}
            isLoading={isLoading}
            limit={3}
            heading="Blog"
            subheading="Explore our latest articles"
          />
        </div>
      </div>

        <div className="flex flex-col items-center py-24 md:py-48 gap-6 px-5">
          <div className="flex flex-col items-center max-w-screen-sm">
            <SectionHeader
              heading="FAQs"
              subheading="Find answers to common questions about Stridez and our services."
              className="text-left xs:text-center"
            />
          </div>
          <div className="relative flex flex-col items-center w-full max-w-screen-sm mx-auto">
            <div className="w-full" ref={faqsRef}>
              {visibleFaqs.map((faq, index) => (
                <motion.div
                  key={index}
                  initial={prefersReducedMotion ? { opacity: 1, x: 0 } : { opacity: 0, x: -20 }}
                  animate={prefersReducedMotion ? {} : faqsInView ? { opacity: 1, x: 0 } : {}}
                  transition={{
                    duration: 0.5,
                    delay: index * 0.1,
                    ease: "easeOut"
                  }}
                  className="flex flex-col justify-start w-full h-full gap-2 py-5 text-left border-b border-secondary-700"
                >
                  <button onClick={() => handleChevron(index)}>
                    <div className="flex items-start justify-between gap-6 text-left">
                      <h4 className="text-2xl font-bold leading-tight font-condensed">
                        {faq.question}
                      </h4>
                      <ChevronIcon
                        className={`size-7 flex-none transition-all ease-linear transform text-secondary-200 ${
                          openItemIds.includes(index) ? "rotate-180" : ""
                        }`}
                      />
                    </div>
                  </button>
                  <div
                    className={`${
                      openItemIds.includes(index) ? "block" : "hidden"
                    }`}
                  >
                    <p className="text-lg leading-normal text-secondary-300">
                      {faq.answer}
                    </p>
                  </div>
                </motion.div>
              ))}
            </div>

            {!showAllFaqs && (
              <>
                <div
                  className="absolute bottom-8 w-full h-56 pointer-events-none"
                  style={{
                    background:
                      "linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0))",
                  }}
                />
                <Button
                  variant="secondary"
                  className="mt-8"
                  roundedFull
                  onClick={() => setShowAllFaqs(true)}
                >
                  Show More
                </Button>
              </>
            )}
          </div>
        </div>

      <div className="relative py-32 md:py-48 overflow-hidden" ref={journeyRef}>
        {/* Linear gradient with blur */}
        <div className="absolute inset-0 overflow-hidden pointer-events-none">
          <div
            className="absolute w-full h-full blur-[100px] opacity-30"
            style={{
              background: "linear-gradient(135deg, #3366FF 0%, black 90%)",
            }}
          />
        </div>

        {/* Content Container */}
        <div className="relative max-w-screen-xl mx-auto px-5 flex flex-col items-center text-center z-10">
          {/* Main Content */}
          <div className="max-w-3xl mx-auto flex flex-col items-center gap-8">
            <motion.h2
              initial={{ opacity: 0, y: 40 }}
              animate={journeyInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 40 }}
              transition={{ duration: 0.6, ease: "easeOut" }}
              className="text-5xl md:text-7xl font-extrabold font-condensed leading-tight"
            >
              Begin Your Journey
            </motion.h2>
            <motion.p
              initial={{ opacity: 0, y: 40 }}
              animate={journeyInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 40 }}
              transition={{ duration: 0.6, delay: 0.1, ease: "easeOut" }}
              className="text-xl md:text-2xl text-secondary-200 max-w-xl"
            >
              Available exclusively for iOS and Android. Premium experience.
              Uncompromised quality.
            </motion.p>

            {/* App Store Buttons */}
            <motion.div
              initial={{ opacity: 0, y: 40 }}
              animate={journeyInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 40 }}
              transition={{ duration: 0.6, delay: 0.2, ease: "easeOut" }}
              className="flex mt-6 gap-x-4 gap-y-2"
              id="download"
            >
              <motion.a
                href="https://apps.apple.com/app/stridez-fitness/id6504737745?platform=iphone"
                target="blank"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                transition={{ duration: 0.2 }}
              >
                <AppStore
                  style={{ height: "3.25rem" }}
                  className="flex-none w-full"
                />
              </motion.a>
              <motion.a
                href="https://play.google.com/store/apps/details?id=com.modular.stridez"
                target="blank"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                transition={{ duration: 0.2 }}
              >
                <GooglePlay
                  style={{ height: "3.25rem" }}
                  className="flex-none w-full"
                />
              </motion.a>
            </motion.div>
          </div>
        </div>
      </div>

      <div className="relative flex flex-col items-center gap-6 px-5 pt-28 pb-32 overflow-hidden" ref={newsletterRef}>
        <div
          className="absolute inset-0 z-0"
          style={{
            backgroundImage: `url(${newsletterBg})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            transform: "scale(1.1)",
          }}
        />
        <div className="absolute inset-0 z-10 bg-black opacity-75" />
        <motion.div
          initial={{ opacity: 0, y: 40 }}
          animate={newsletterInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 40 }}
          transition={{ duration: 0.6, ease: "easeOut" }}
          className="relative z-20 flex flex-col items-center justify-center gap-4"
        >
          <SectionHeader
            heading="Join our newsletter"
            subheading="Receive fitness tips, special offers, and updates directly to your inbox."
            className="text-center"
          />
        </motion.div>
        <form
          onSubmit={submitNewsletter}
          className="relative z-20 w-full max-w-md mx-auto"
        >
          <motion.div
            initial={{ opacity: 0, y: 40 }}
            animate={newsletterInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 40 }}
            transition={{ duration: 0.6, delay: 0.1, ease: "easeOut" }}
            className="relative"
          >
            <input
              type="email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                setTouched(true);
              }}
              placeholder="Enter your email"
              className="w-full px-6 py-4 text-white bg-secondary-900/90 rounded-full border border-secondary-700 pr-36 placeholder:text-secondary-400 focus:outline-none focus:border-secondary-400 transition-colors"
            />
            <button
              type="submit"
              disabled={
                !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email)
              }
              className="absolute right-2 top-1/2 -translate-y-1/2 px-6 py-2.5 bg-primary text-black rounded-full font-bold transition-opacity disabled:opacity-50"
            >
              Subscribe
            </button>
          </motion.div>
          {touched &&
            !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email) && (
              <p className="mt-2 text-sm text-red-500 ml-6">
                * Please enter a valid email address
              </p>
            )}
        </form>
      </div>
    </div>
  );
};

export default Home;
