import React from "react";
import { Link } from "react-router-dom";
import logo from "../icons/logo.svg";
import { scrollTo } from "../js/utils";
import CachedImage from "./CachedImage";
import { ReactComponent as AppStore } from "../icons/AppStore.svg";
import { ReactComponent as GooglePlay } from "../icons/GooglePlay.svg";

const Footer = () => {
  return (
    <footer
      className="relative z-50 px-5 bg-gradient-to-br from-secondary-900 to-secondary-950 md:px-6 lg:px-8"
      style={{
        boxShadow: "0 -1px 4px rgba(0,0,0,0.1), 0 -3px 6px rgba(0,0,0,0.2)",
      }}
    >
      <div className="w-full max-w-screen-xl py-6 mx-auto lg:py-8">
        <div className="md:flex md:justify-between">
          <div className="mb-8 md:mb-0">
            <Link to="/" onClick={() => scrollTo()}>
              <CachedImage src={logo} alt="logo" />
            </Link>
            <p className="max-w-xs mt-4 text-secondary-300">
              Download our app and start your fitness journey today!
            </p>
            <div className="flex mt-4 gap-x-3 gap-y-2">
              <a
                href="https://apps.apple.com/app/stridez-fitness/id6504737745?platform=iphone"
                target="blank"
              >
                <AppStore
                  style={{ height: "2.75rem" }}
                  className="flex-none w-full"
                />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.modular.stridez"
                target="blank"
              >
                <GooglePlay
                  style={{ height: "2.75rem" }}
                  className="flex-none w-full"
                />
              </a>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-8 sm:gap-6 xs:grid-cols-2">
            <div>
              <h2 className="mb-4 text-sm font-semibold text-white uppercase">
                Navigation
              </h2>
              <div className="flex flex-col font-medium text-secondary-200">
                <Link to="/" className="mb-3 hover:underline">
                  Home
                </Link>
                <Link to="/blog" className="mb-3 hover:underline">
                  Blog
                </Link>
                <Link to="/support" className="mb-3 hover:underline">
                  Support
                </Link>
                {/* <Link to="/plans" className="mb-4 hover:underline">
                  Plans
                </Link> */}
                {/* <Link to="/account_deletion" className="mb-4 hover:underline">
                  Request Deletition
                </Link> */}
              </div>
            </div>
            <div>
              <h2 className="mb-4 text-sm font-semibold text-white uppercase">
                Legal
              </h2>
              <ul className="font-medium text-secondary-200">
                <li className="mb-3">
                  <Link to="/docs/terms" className="mb-3 hover:underline">
                    Terms &amp; Conditions
                  </Link>
                </li>
                <li className="mb-3">
                  <Link to="/docs/privacy" className="hover:underline">
                    Privacy Policy
                  </Link>
                </li>
                {/* <li className="mb-3">
                  <a
                    href="https://stridez.io/docs/community"
                    className="hover:underline"
                  >
                    Guidelines
                  </a>
                </li> */}
                <li>
                  <Link to="/docs/refund" className="hover:underline">
                    Refund
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <hr className="my-6 sm:mx-auto border-secondary-700 lg:my-8" />
        <div className="flex items-center justify-between">
          <span className="text-sm sm:text-center text-secondary-300">
            © 2024{" "}
            <a href="https://modular-web.com/" className="hover:underline">
              Modular
            </a>
            . All Rights Reserved.
          </span>
          <div className="flex justify-center">
            <a
              href="https://discord.gg/jq6nVSTRnV"
              target="_blank"
              rel="noreferrer"
              className="text-white"
            >
              <svg
                className="w-4 h-4"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 21 16"
              >
                <path d="M16.942 1.556a16.3 16.3 0 0 0-4.126-1.3 12.04 12.04 0 0 0-.529 1.1 15.175 15.175 0 0 0-4.573 0 11.585 11.585 0 0 0-.535-1.1 16.274 16.274 0 0 0-4.129 1.3A17.392 17.392 0 0 0 .182 13.218a15.785 15.785 0 0 0 4.963 2.521c.41-.564.773-1.16 1.084-1.785a10.63 10.63 0 0 1-1.706-.83c.143-.106.283-.217.418-.33a11.664 11.664 0 0 0 10.118 0c.137.113.277.224.418.33-.544.328-1.116.606-1.71.832a12.52 12.52 0 0 0 1.084 1.785 16.46 16.46 0 0 0 5.064-2.595 17.286 17.286 0 0 0-2.973-11.59ZM6.678 10.813a1.941 1.941 0 0 1-1.8-2.045 1.93 1.93 0 0 1 1.8-2.047 1.919 1.919 0 0 1 1.8 2.047 1.93 1.93 0 0 1-1.8 2.045Zm6.644 0a1.94 1.94 0 0 1-1.8-2.045 1.93 1.93 0 0 1 1.8-2.047 1.918 1.918 0 0 1 1.8 2.047 1.93 1.93 0 0 1-1.8 2.045Z" />
              </svg>
              <span className="sr-only">Discord community</span>
            </a>
            {/* <a href="https://twitter.com" className="text-white ms-5">
              <svg
                className="w-4 h-4"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 17"
              >
                <path
                  fillRule="evenodd"
                  d="M20 1.892a8.178 8.178 0 0 1-2.355.635 4.074 4.074 0 0 0 1.8-2.235 8.344 8.344 0 0 1-2.605.98A4.13 4.13 0 0 0 13.85 0a4.068 4.068 0 0 0-4.1 4.038 4 4 0 0 0 .105.919A11.705 11.705 0 0 1 1.4.734a4.006 4.006 0 0 0 1.268 5.392 4.165 4.165 0 0 1-1.859-.5v.05A4.057 4.057 0 0 0 4.1 9.635a4.19 4.19 0 0 1-1.856.07 4.108 4.108 0 0 0 3.831 2.807A8.36 8.36 0 0 1 0 14.184 11.732 11.732 0 0 0 6.291 16 11.502 11.502 0 0 0 17.964 4.5c0-.177 0-.35-.012-.523A8.143 8.143 0 0 0 20 1.892Z"
                  clipRule="evenodd"
                />
              </svg>
              <span className="sr-only">Twitter page</span>
            </a> */}
            <a
              href="https://www.instagram.com/usestridez"
              target="_blank"
              rel="noreferrer"
              className="text-white ms-5"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-4 h-4"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  fill="#fff"
                  d="M17 4.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z"
                ></path>
                <path
                  fill="#fff"
                  fillRule="evenodd"
                  d="M10 15a5 5 0 100-10 5 5 0 000 10zm0-2a3 3 0 100-6 3 3 0 000 6z"
                  clipRule="evenodd"
                ></path>
                <path
                  fill="#fff"
                  fillRule="evenodd"
                  d="M0 9.6c0-3.36 0-5.04.654-6.324A6 6 0 013.276.654C4.56 0 6.24 0 9.6 0h.8c3.36 0 5.04 0 6.324.654a6 6 0 012.622 2.622C20 4.56 20 6.24 20 9.6v.8c0 3.36 0 5.04-.654 6.324a6 6 0 01-2.622 2.622C15.44 20 13.76 20 10.4 20h-.8c-3.36 0-5.04 0-6.324-.654a6 6 0 01-2.622-2.622C0 15.44 0 13.76 0 10.4v-.8zM9.6 2h.8c1.713 0 2.878.002 3.778.075.877.072 1.325.202 1.638.361a4 4 0 011.748 1.748c.16.313.29.761.36 1.638.074.9.076 2.065.076 3.778v.8c0 1.713-.002 2.878-.075 3.778-.072.877-.202 1.325-.361 1.638a4 4 0 01-1.748 1.748c-.313.16-.761.29-1.638.36-.9.074-2.065.076-3.778.076h-.8c-1.713 0-2.878-.002-3.778-.075-.877-.072-1.325-.202-1.638-.361a4 4 0 01-1.748-1.748c-.16-.313-.29-.761-.36-1.638C2.001 13.278 2 12.113 2 10.4v-.8c0-1.713.002-2.878.075-3.778.072-.877.202-1.325.361-1.638a4 4 0 011.748-1.748c.313-.16.761-.29 1.638-.36C6.722 2.001 7.887 2 9.6 2z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <span className="sr-only">Instagram page</span>
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
