import React from "react";
import BackButton from "../../components/BackButton";

const RefundPolicy = () => {
  return (
    <div className="w-full max-w-screen-md px-4 pt-4 pb-12 rich-text mx-auto text-black bg-white md:pt-8 md:px-6 lg:px-8 xl:px-4 docs">
      <BackButton />
      <h1 className="mb-6 text-3xl text-black font-bold text-center">
        CANCELLATION & REFUND POLICY
      </h1>

      <p className="mb-4">
        At <strong>Stridez</strong>, we strive to offer our users a flexible and
        straightforward cancellation and refund policy. Our goal is to ensure
        that your experience with our services is satisfying and accommodating
        to your needs. This policy outlines the conditions under which
        cancellations and refunds are processed for our various subscription
        tiers.
      </p>

      <p className="mb-4">
        Please read this Policy carefully, as it forms a binding agreement
        between you and Stridez. This Policy shall be read in conjunction with
        our <strong>Terms of Service.</strong>
      </p>

      <h2 className="mt-6 mb-2 text-xl font-bold">1. DEFINITIONS</h2>
      <p className="mb-4">
        Unless expressly stated otherwise, all the capitalized terms used in
        this Policy shall have the same meaning as ascribed to them in the{" "}
        <strong>Terms of Service</strong>.
      </p>

      <h2 className="mt-6 mb-2 text-xl font-bold">2. SERVICE DESCRIPTION</h2>
      <p className="mb-4">
        We use our best endeavour to provide accurate and materially complete
        description of all the services, subscription, and its features.
        However, we do not warrant that such description will always be
        accurate, complete, reliable, current, or error-free. We reserve the
        right to bring new features, and/or take out certain features of our
        services at any time at our sole discretion.
      </p>

      <h2 className="mt-6 mb-2 text-xl font-bold">
        3. SUBSCRIPTION CANCELLATION
      </h2>
      <ul className="pl-6 mb-4 list-disc">
        <li>
          <strong>User-Initiated Cancellation</strong>: Subscribers may cancel
          their subscription at any time through their account settings within
          the Stridez App or website. To avoid charges for the upcoming billing
          cycle, subscribers must cancel at least 24 hours before the next
          scheduled renewal date.
        </li>
        <li>
          <strong>Company-Initiated Cancellation</strong>: We reserve the right
          to cancel a subscription if we fail to process payment due to expired
          or invalid payment methods. We will attempt to notify you promptly in
          such cases, allowing you to update your payment information to
          maintain your subscription.
        </li>
      </ul>

      <h2 className="mt-6 mb-2 text-xl font-bold">4. TRIAL PERIOD</h2>
      <p className="mb-4">
        Stridez offers a free trial period for all new users to explore our
        features. Users must cancel their subscription before the trial period
        ends to avoid being charged the subscription fee. Cancellations made
        after the trial period will not qualify for a refund for the
        first billing cycle.
      </p>

      <h2 className="mt-6 mb-2 text-xl font-bold">5. REFUND</h2>
      <ul className="pl-6 mb-4 list-disc">
        <li>
          <strong>Monthly Subscriptions:</strong> Refunds for monthly
          subscriptions are typically not provided. However, exceptional cases
          will be considered on a case-by-case basis at the sole discretion of
          the Company. If a refund is granted, it will be prorated based on the
          remaining unused portion of the subscription.
        </li>
        <li>
          <strong>Annual Subscriptions:</strong> Users who cancel their annual
          subscription may be eligible for a refund within 14 days of purchase
          or renewal, considering the unused portion of the subscription. After
          14 days, refunds will not be granted.
        </li>
        <li>
          <strong>Lifetime Membership</strong>: Lifetime subscriptions are
          eligible for a refund within 30 days of purchase. After this period,
          refunds cannot be processed due to the nature of the lifetime
          commitment.
        </li>
        <li>
          <strong>Purchases made outside of website or App:</strong> Our refund
          policy is only applicable to subscriptions purchased directly through
          the Stridez App or website. Subscriptions or purchases made through
          third-party providers or app stores must be cancelled or refunded
          through the respective platform, according to their terms and
          policies.
        </li>
        <li>
          <strong>Points and In-App Purchases:</strong> Points earned through
          app usage or purchased within the app are non-refundable. In-app
          purchases made with points, such as streak freezes or repairs, are
          final and non-refundable once used. Unused points will be forfeited
          upon account cancellation or termination.
        </li>
      </ul>

      <h2 className="mt-6 mb-2 text-xl font-bold">6. REFUND PROCESS</h2>
      <ul className="pl-6 mb-4 list-disc">
        <li>
          To request a refund, users should contact our customer support team at{" "}
          <a
            href="mailto:support@stridez.io"
            className="text-blue-600 hover:underline"
          >
            support@stridez.io
          </a>
          , providing the account information and the reason for the refund
          request.
        </li>
        <li>
          Eligible refunds will be processed back to the original method of
          payment within a reasonable timeframe, usually within{" "}
          <strong>7-10 business days</strong>, depending on the payment
          provider.
        </li>
      </ul>

      <h2 className="mt-6 mb-2 text-xl font-bold">
        7. CHANGES TO SUBSCRIPTION PRICE
      </h2>
      <p className="mb-4">
        Stridez reserves the right to modify subscription prices. Any price
        changes will be communicated to subscribers in advance, providing the
        option to cancel before the changes take effect.
      </p>

      <h2 className="mt-6 mb-2 text-xl font-bold">
        8. GOVERNING LAW AND DISPUTE RESOLUTION
      </h2>
      <p className="mb-4">
        Unless provided by the relevant statute, rules, or directives applicable
        to the jurisdiction in which you reside, in case of any claims, disputes
        or controversies arising out of or in relation to the services, or this
        Policy, the same shall be dealt with as per the Governing Law and
        Dispute Resolution clause as mentioned in the{" "}
        <strong>Terms of Service</strong>.
      </p>

      <h2 className="mt-6 mb-2 text-xl font-bold">9. UPDATES TO THIS POLICY</h2>
      <p className="mb-4">
        We may add to or change or update this{" "}
        <strong>Cancellation & Refund Policy</strong> at any time, from time to
        time, entirely at our own discretion, with or without any prior written
        notice. You are responsible for checking this Policy periodically. Your
        use of the platform after any amendments to this Policy shall constitute
        your acceptance to such amendments.
      </p>

      <p className="mt-6">
        Last updated on <strong>August 8, 2024</strong>
      </p>
    </div>
  );
};

export default RefundPolicy;
