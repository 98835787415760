import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

const BlogItem = ({ image, title, description, url }) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{ duration: 0.5, ease: "easeOut" }}
      className="flex flex-col w-full gap-6"
    >
      <Link className="overflow-hidden rounded-xl" to={`/blog/${url}`}>
        <motion.img 
          whileHover={{ scale: 1.1 }}
          transition={{ duration: 0.3 }}
          className="object-cover aspect-[4/3]" 
          src={image?.url} 
          alt={title} 
        />
      </Link>
      <motion.div 
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.3, delay: 0.2 }}
        className="flex flex-col gap-1.5"
      >
        <Link to={`/blog/${url}`}>
          <motion.h4 
            whileHover={{ color: "#FF3366" }}
            transition={{ duration: 0.2 }}
            className="text-2xl font-bold leading-tight tracking-tight text-white"
          >
            {title}
          </motion.h4>
        </Link>
        <p className="text-base leading-6 text-secondary-400 line-clamp-2">
          {description}
        </p>
      </motion.div>
    </motion.div>
  );
};

export default BlogItem;
