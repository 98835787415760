import React from "react";

const SectionHeader = ({ heading, subheading, className, containerLarge }) => {
  return (
    <div className={`flex flex-col ${containerLarge ? "max-w-2xl" : "max-w-xl"} gap-3 ${className}`}>
      <h2 className="text-4xl font-extrabold leading-tight xs:text-5xl font-condensed">{heading}</h2>
      <p className={`${containerLarge ? "w-full" : "max-w-lg"} leading-snug text-xl xs:text-2xl text-secondary-300`}>{subheading}</p>
    </div>
  );
};

export default SectionHeader;
