import React from "react";
import { ReactComponent as AppStore } from "../icons/AppStore.svg";
import { ReactComponent as GooglePlay } from "../icons/GooglePlay.svg";
import { motion } from "framer-motion";

const Hero = () => {
  return (
    <div className="relative flex items-end justify-center w-full py-4 sm:justify-end sm:items-center hero-background">
      <div
        className="absolute inset-0"
        style={{
          background:
            "linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4), black)",
        }}
      ></div>
      <div className="absolute inset-0 bg-black opacity-20"></div>
      <div className="relative flex flex-col items-center w-full max-w-screen-xl gap-16 p-4 mx-auto sm:items-end">
        <div className="flex flex-col items-center max-w-sm gap-1 text-center sm:items-start sm:text-left">
          <motion.p
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="text-lg font-semibold uppercase text-primary"
          >
            TRY 10-DAY FREE
          </motion.p>
          <motion.h1
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.1 }}
            className="text-6xl font-extrabold font-condensed"
          >
            Your Smart Fitness Companion
          </motion.h1>
          <motion.p
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
            className="mt-4 font-medium text-secondary-200 max-w-screen-xs"
            style={{ fontSize: '1.35rem', lineHeight: '1.25' }}
          >
            Transform every workout into progress. Train smarter, achieve more.
          </motion.p>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.3 }}
            className="flex mt-6 gap-x-4 gap-y-2"
            id="download"
          >
            <motion.a
              href="https://apps.apple.com/app/stridez-fitness/id6504737745?platform=iphone"
              target="blank"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              transition={{ duration: 0.2 }}
            >
              <AppStore
                style={{ height: "3.25rem" }}
                className="flex-none w-full"
              />
            </motion.a>
            <motion.a
              href="https://play.google.com/store/apps/details?id=com.modular.stridez"
              target="blank"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              transition={{ duration: 0.2 }}
            >
              <GooglePlay
                style={{ height: "3.25rem" }}
                className="flex-none w-full"
              />
            </motion.a>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
