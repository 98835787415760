import React from "react";
import BackButton from "../../components/BackButton";

const Privacy = () => {
  return (
    <div className="w-full max-w-screen-md px-4 pt-4 pb-12 rich-text mx-auto text-black bg-white md:pt-8 md:px-6 lg:px-8 xl:px-4 docs">
      <BackButton />
      <h1 className="mb-6 text-3xl text-black font-bold text-center">
        PRIVACY POLICY
      </h1>
      <p className="mb-4">
        This notice describes the privacy policy ("Privacy Policy" or "Policy")
        of https://stridez.io/, and/or its related mobile application,
        "Stridez", as available on Google Play Store and Apple App Store
        (hereinafter referred to as the "website" or "app") which is operated
        by:
      </p>
      <p className="mb-4">
        <strong>Company Name:</strong> Modular Web Ltd
      </p>
      <p className="mb-4">
        <strong>Company Registration Number:</strong> 14065707
      </p>
      <p className="mb-4">
        <strong>Registered Office:</strong> 71-75 Shelton Street, Covent Garden,
        London, United Kingdom, WC2H 9JQ
      </p>
      <p className="mb-4">
        (hereinafter referred to the "Company" or "Stridez" or "us" or "our" or
        "we"). In this Policy, you shall be referred to as "you" or "your" or
        "user(s)".
      </p>

      <p className="mb-4">
        Your privacy is of paramount importance to us. This Privacy Policy
        outlines our practices regarding the collection, use, and disclosure of
        your information through your use of our website and app. We are
        committed to protecting the privacy and security of the personal
        information of our users. This policy is designed to inform you about
        how we handle your personal data, the types of information we collect,
        how it is processed, and for what purposes. The use of information
        collected through our App/website shall be limited to the purposes
        described under this Privacy Policy and our Terms of Service.
      </p>

      <p className="mb-4">
        By using our App, website or availing our services or providing your
        personal information to us, you are accepting and consenting to the
        practices described in this policy. Please note that this includes
        consenting to the processing of any personal information that you
        provide, as described below.
      </p>

      <h2 className="mt-6 mb-4 text-2xl font-bold">
        IF YOU DO NOT AGREE WITH THESE PRACTICES, PLEASE DO NOT USE THE SERVICES
        OR THE APP OR WEBSITE OR PROVIDE US WITH ANY OF YOUR PERSONAL
        INFORMATION.
      </h2>

      <h2 className="mt-6 mb-4 text-2xl font-bold">
        1. What information about the users do we collect?
      </h2>
      <p className="mb-4">
        At Stridez, we are committed to protecting your privacy and ensuring a
        safe and enjoyable user experience. To provide our services, we may
        collect and process the following types of information:
      </p>
      <ol className="mb-4 list-decimal list-inside">
        <li>
          Personal Information: When creating an account or using our services,
          you may provide us with personal information such as your first name,
          date of birth, height, weight, and email address. This information is
          essential for setting up your user profile and personalizing your
          experience with the Stridez App and website. When you choose to start
          a free trial, we may collect additional information including
          payment details.
        </li>
        <li>
          Health and Fitness Data: As you use the App, you may enter or upload
          additional health-related data to receive personalized workout and
          meal plans. This may include your physical activity levels, dietary
          preferences, and specific fitness goals.
        </li>
        <li>
          Integration with Apple Health or Android Health: For users who opt-in,
          the App integrates with platforms like Apple Health and Android Health
          to access health data such as daily step counts. This is solely for
          displaying this data within the App and enhancing your experience. We
          only collect and store this data with your explicit consent and for
          the purposes specified.
        </li>
        <li>
          User Content: Any content you create or share within the App,
          including progress photos, comments, and fitness achievements, is
          collected to facilitate community engagement and personal progress
          tracking.
        </li>
        <li>
          Usage Details and Device Information: As you navigate through and
          interact with our Website and App, we may automatically collect
          certain details of your access and usage, including traffic data,
          logs, and other communication data, as well as information about your
          device, operating system, and internet connection.
        </li>
        <li>
          Communications with Us: When you contact our support team or
          communicate with us through other channels, we collect the information
          you provide in those communications.
        </li>
        <li>
          App Permissions: Our App requires the following permissions from users
          to provide a comprehensive and user-friendly fitness experience:
        </li>
        <ol className="mb-4 ml-6 list-decimal list-inside">
          <li>
            Health Data Access: The App requests permission to access health
            data from Apple Health and Android Health platforms. This access
            allows Stridez to display the number of steps you have taken each
            day, contributing to a holistic view of your daily physical
            activity. The primary use of this data is to enhance your experience
            by allowing the App to provide personalized fitness tracking and
            insights. It's important to note that we currently display this data
            for your convenience and do not store it.
          </li>
          <li>
            Notifications: Stridez uses push notifications to remind you of your
            workout schedules, meal plans, or when it's time to log your water
            intake. You have the control to manage these notifications within
            your device's settings.
          </li>
          <li>
            Camera Access: For users who wish to track their physical progress
            through images, the App requests camera access. This permission
            enables you to take progress pictures directly within the App.
            Camera access is requested only with your consent, and you control
            the photos that are taken and stored within the App.
          </li>
        </ol>
        <li>
          AI-Generated Content Data: Information used to create and refine
          AI-generated workout and meal plans, including your fitness goals,
          dietary preferences, and activity levels.
        </li>
        <li>
          Gamification Data: Information related to your participation in
          app-based challenges, leaderboards, and point systems, including your
          scores, rankings, and achievements.
        </li>
        <li>
          Streak and Progress Data: Information about your continuous usage of
          the app, including login streaks and milestone achievements.
        </li>
      </ol>

      <h2 className="mt-6 mb-4 text-2xl font-bold">
        2. What is the lawful basis for which we use your personal information?
      </h2>
      <p className="mb-4">
        You hereby acknowledge that all processing of your personal information
        will be justified by a "lawful ground" for processing. In most cases,
        processing will be justified on the basis that:
      </p>
      <ol className="mb-4 list-decimal list-inside">
        <li>
          Consent: We obtain your explicit consent for processing personal data
          in several instances, such as when you agree to receive marketing
          communications or when we collect health-related data via the App.
          This consent is given freely and can be withdrawn at any time by the
          user, affecting the further use of personal information based on this
          consent from that point forward.
        </li>
        <li>
          Performance of a contract: We use personal information necessary for
          the performance of our contract with you. This includes any
          information required to manage your account, provide our services,
          including access to the App and its features, and to fulfill our
          obligations under the Terms of Service. Processing your data is
          essential for us to provide personalized fitness plans, track your
          progress, and offer support.
        </li>
        <li>
          Legal obligations: We may process personal information to comply with
          our legal obligations. This includes any legal requirements to
          maintain accurate business records, comply with lawful requests by
          public authorities, and adhere to regulatory requirements specific to
          our business operations.
        </li>
        <li>
          Legitimate interests: We process certain information under the lawful
          basis of legitimate interests. This includes data processing
          activities carried out for the purposes of improving our services,
          protecting against fraud or misuse of our services, and enhancing user
          experience. When relying on legitimate interests, we ensure that the
          processing is necessary and balanced against the individual's rights
          and freedoms.
        </li>
        <li>
          Vital Interests: In rare circumstances, we may process personal
          information when it is necessary to protect someone's vital interests,
          such as in emergencies where the processing of personal data could
          help ensure the safety and security of our users or others.
        </li>
      </ol>

      <p className="mb-4">
        In any case, we will gladly help to clarify the specific legal basis
        that applies to the processing, and in particular whether the provision
        of personal data is a statutory or contractual requirement, or a
        requirement necessary to enter into a contract.{" "}
        <span className="underline">
          Feel free to contact us for this purpose at{" "}
          <a href="mailto:support@stridez.io" className="text-blue-600">
            support@stridez.io
          </a>
          .
        </span>
      </p>

      {/* Previous content... */}

      <h2 className="mt-6 mb-4 text-2xl font-bold">
        3. How do we use this information?
      </h2>
      <p className="mb-4">
        At Stridez, we utilize the information we collect for various purposes
        aimed at enhancing your user experience and providing you with our
        services. The ways in which we use this information include:
      </p>
      <ol className="mb-4 list-decimal list-inside">
        <li>
          Personalized Fitness and Nutrition Plans: We use the information to
          create and provide personalized fitness and nutrition plans tailored
          to your specific health goals, preferences, and dietary needs.
        </li>
        <li>
          Account Management: Your information helps us in managing your
          account, including registration, profile setup, and user support,
          ensuring a smooth and customized user experience.
        </li>
        <li>
          Service Improvement: We analyze user data to improve the App's
          functionality, interface, and content, aiming to enhance overall user
          satisfaction and engagement with our services.
        </li>
        <li>
          Communication: We utilize your contact details to communicate with you
          regarding your account, updates, promotional offers, and any changes
          to our terms, services, or policies.
        </li>
        <li>
          Security and Fraud Prevention: Information collected aids in
          protecting against fraudulent activities, ensuring the integrity and
          security of our services and user accounts.
        </li>
        <li>
          Health Data Utilization: Health-related information, such as steps
          taken, is used to provide insights and feedback on your physical
          activity levels, contributing to the overall personalization of the
          service.
        </li>
        <li>
          Progress Tracking: We use your health and fitness data, including
          progress pictures and activity logs, to track and visually represent
          your fitness journey within the App.
        </li>
        <li>
          Legal Compliance: Your information is used to comply with legal
          obligations, such as data protection laws, and to respond to lawful
          requests by authorities.
        </li>
        <li>
          Marketing and Promotions: Based on your consent, we may use your
          information to send you marketing materials and promotional offers
          that we believe may be of interest to you.
        </li>
        <li>
          Analytics and Performance Improvement: To monitor and analyze usage
          and trends to improve our website and App's functionality and user
          interface.
        </li>
        <li>
          Terms of Service Enforcement: To enforce our terms, conditions, and
          policies for business purposes, to comply with legal and regulatory
          requirements, and to protect our business.
        </li>
        <li>
          Personalization of AI Content: We use your personal information,
          health data, and app usage patterns to generate personalized workout
          and meal plans through our AI algorithms.
        </li>
        <li>
          Gamification and Engagement: Your activity data and achievements are
          used to populate leaderboards, award points, and provide motivational
          features to enhance your app experience.
        </li>
        <li>
          Streak and Reward Management: We track your usage patterns to maintain
          streak counts and determine eligibility for rewards and special
          features.
        </li>
      </ol>

      <h3 className="mt-4 mb-2 text-xl font-bold">AI-Generated Content</h3>
      <p className="mb-4">
        Our app utilizes artificial intelligence to create personalized workout
        and meal plans. This process involves:
      </p>
      <ul className="mb-4 list-disc list-inside">
        <li>
          Using your provided information and app usage data to inform AI
          algorithms.
        </li>
        <li>
          Generating content that is tailored to your specific fitness goals and
          preferences.
        </li>
        <li>
          Continuously refining recommendations based on your feedback and
          progress.
        </li>
      </ul>
      <p className="mb-4">
        Please note that while our AI-generated content is designed to be
        helpful, it is not a substitute for professional medical advice,
        diagnosis, or treatment. Always consult with a qualified healthcare
        provider before starting any new fitness or nutrition program.
      </p>
      <p className="mb-4">
        You have the right to request information about how your data is used in
        AI content generation and to opt out of personalized AI features if
        desired.
      </p>

      <h2 className="mt-6 mb-4 text-2xl font-bold">
        4. How can you delete your information and account?
      </h2>
      <p className="mb-4">
        Your information, account, and the content that you share on our website
        and App is yours. You can at any time delete the same. However, you
        acknowledge that we may retain some of the information so deleted for a
        reasonable period in order to comply with legal requests. To delete your
        Stridez account and personal information, you can initiate the process
        directly within the Stridez App or by contacting our customer support
        team via email at{" "}
        <a href="mailto:support@stridez.io" className="text-blue-600">
          support@stridez.io
        </a>
        . Please provide your account details and expressly state your desire to
        delete your account. Upon receiving your request, we will verify your
        identity to ensure the security of your account. This process may
        involve asking you to provide additional information or to confirm your
        identity through email or other secure methods.
      </p>

      <h2 className="mt-6 mb-4 text-2xl font-bold">
        5. Cookies and Similar Technologies
      </h2>
      <p className="mb-4">
        Cookies are bits of electronic information that a website may transfer
        to a visitor's computer to identify specific information about the
        visitor's visits to other websites. We may use automated technologies
        including the use of web server logs to collect IP addresses, device
        details, cookies, and web beacons. The website uses a browser feature
        known as a cookie, which assigns a unique identification to your
        computer. However, in case you do not wish for us to collect such
        information, you may change the cookie settings on your web browser.
      </p>
      <p className="mb-4">
        We use tracking technologies to support our gamification features,
        including maintaining accurate leaderboard data and tracking in-app
        achievements. These technologies are essential for providing a seamless
        and engaging user experience.
      </p>

      <h2 className="mt-6 mb-4 text-2xl font-bold">
        6. In-App Purchases and Virtual Currency
      </h2>
      <p className="mb-4">
        Our app uses a point system as a form of virtual currency. Please note:
      </p>
      <ul className="mb-4 list-disc list-inside">
        <li>
          Points are earned through various in-app activities and achievements.
        </li>
        <li>
          Points can be used for in-app purchases such as streak freezes or
          repairs.
        </li>
        <li>
          Points have no monetary value outside of the app and cannot be
          exchanged for cash.
        </li>
        <li>
          Purchases made with real currency are subject to our Refund Policy.
        </li>
      </ul>

      <h2 className="mt-6 mb-4 text-2xl font-bold">
        7. Who do we share your information with?
      </h2>
      <p className="mb-4">
        Stridez takes your privacy seriously and strives to ensure that your
        personal information is treated with care and respect. We may share your
        information in the following circumstances:
      </p>
      <ol className="mb-4 list-decimal list-inside">
        <li>
          Third-party Service Providers: We share information with third-party
          service providers who perform services on our behalf, such as website
          hosting, data analysis, payment processing, information technology and
          related infrastructure provision, customer service, email delivery,
          and auditing services. These providers are bound by confidentiality
          agreements and are not permitted to use your information for any other
          purpose than to carry out the services they are performing for us.
        </li>
        <li>
          Employees: Your information may also be accessed and used by our
          employees to the extent necessary to perform their job functions. This
          includes providing customer support, developing and improving our
          services, and managing account and service-related activities. Our
          employees are bound by strict confidentiality agreements and company
          policies that mandate the responsible handling and protection of
          personal information, ensuring it is used only for legitimate business
          purposes and in compliance with our privacy policy and applicable
          laws.
        </li>
        <li>
          Legal Obligations: In certain situations, Stridez may be required to
          disclose personal data in response to lawful requests by public
          authorities, including to meet national security or law enforcement
          requirements. We may also share information to comply with a judicial
          proceeding, court order, or legal process served on our website or
          app.
        </li>
        <li>
          Business Transfers: In the event of a merger, acquisition, or sale of
          all or a portion of our assets, your information may be transferred to
          the acquiring entity as part of the transaction. We will notify you of
          such transfers and any changes to our privacy practices.
        </li>
        <li>
          Aggregated Data: We may share aggregated and anonymized data that
          cannot be used to identify individuals. This information is used for
          analytical and research purposes.
        </li>
        <li>
          With Your Consent: If you provide explicit consent, we may share your
          data for other specific purposes. You have the option to opt in or opt
          out of such activities.
        </li>
        <li>
          Third-party Links: Stridez may contain links to third-party websites
          or services. We are not responsible for the privacy practices or the
          content of these third-party sites. You should review the privacy
          policies of these third parties to understand how they collect and use
          your information.
        </li>
        <li>
          Community and Gamification Features: Certain information, such as your
          username and achievement data, may be visible to other users through
          leaderboards and community engagement features.
        </li>
      </ol>
      <p className="mb-4">
        It's important to emphasize that Stridez does not sell or rent your
        personal information to third parties. We take all necessary steps to
        safeguard your privacy and security. Please be aware that when you
        engage in user-to-user interactions, your information is shared with
        those users as part of the intended platform functionality.
      </p>

      <h2 className="mt-6 mb-4 text-2xl font-bold">
        8. Storage and Security of Information
      </h2>
      <p className="mb-4">
        At Stridez, we prioritize the security and integrity of your personal
        information. We implement robust measures to protect your data and
        ensure its safe storage. Here's how we handle this crucial aspect.
      </p>
      <ol className="mb-4 list-decimal list-inside">
        <li>
          Storage: Stridez, operated by MODULAR WEB LTD, stores your personal
          information on secure servers provided by our hosting services,
          Netlify for the frontend and Xano for the backend. These servers are
          located in data centers that are equipped with state-of-the-art
          security measures to protect against unauthorized access, alteration,
          disclosure, or destruction of your personal data.
        </li>
        <li>
          Local Servers: In addition to our hosting provider, we also store
          information on secure local servers. These servers are maintained in
          accordance with strict security protocols and are located in secure
          facilities with restricted access.
        </li>
        <li>
          Access Control: Access to your data is restricted to authorized
          personnel who require such access to fulfill their responsibilities.
          All individuals with access to user data are bound by strict
          confidentiality agreements.
        </li>
        <li>
          Encryption: We employ robust encryption methods to secure your data
          both in transit and at rest. Data transmitted between your device and
          our servers is protected using Secure Socket Layer (SSL) technology or
          similar protocols, ensuring that your personal information is
          safeguarded against interception or eavesdropping.
        </li>
        <li>
          Data Retention: We retain your information for as long as necessary to
          provide our services, comply with legal obligations, resolve disputes,
          and enforce our policies. Once data is no longer required for these
          purposes, it is securely deleted or anonymized.
        </li>
        <li>
          Security Measures: Our security measures include firewalls, intrusion
          detection, and continuous monitoring to identify and respond to
          potential threats. We employ routine security assessments to
          strengthen our defenses.
        </li>
        <li>
          User Authentication: We use industry-standard authentication and
          authorization practices to confirm the identity of users and restrict
          unauthorized access to accounts.
        </li>
        <li>
          Password Protection: We encourage strong password practices, including
          password complexity and periodic password updates, to enhance the
          security of user accounts.
        </li>
        <li>
          Training and Awareness: Our team is educated on security best
          practices and data protection. We continuously monitor and update our
          procedures to respond to evolving security threats.
        </li>
      </ol>
      <p className="mb-4">
        While we implement stringent security measures, it's important to
        remember that no method of data transmission over the internet or
        electronic storage can be completely foolproof. We continuously work to
        improve our security practices and remain dedicated to protecting your
        information.
      </p>

      <h2 className="mt-6 mb-4 text-2xl font-bold">9. Links to other Sites</h2>
      <p className="mb-4">
        The website and App may contain links to third-party websites and online
        services that are not owned or controlled by us. We have no control
        over, and assume no responsibility for such websites and online
        services. Be aware when you leave the website; we suggest you read the
        terms and privacy policy of each third-party website, and online service
        that you visit.
      </p>

      <h2 className="mt-6 mb-4 text-2xl font-bold">
        10. Rights of Australian Users
      </h2>
      <p className="mb-4">
        Stridez values the privacy and rights of our Australian users and is
        committed to ensuring compliance with the{" "}
        <strong>Privacy Act 1988</strong>. This section of our Privacy Policy
        outlines the rights and protections granted to Australian users under
        the Act.
      </p>
      <ol className="mb-4 list-decimal list-inside">
        <li>
          Access to Personal Information: Australian users have the right to
          request access to the personal information we hold about them. You may
          request details about the information collected, how it is used, and
          to whom it has been disclosed. To request access, please contact us at{" "}
          <a href="mailto:support@stridez.io" className="text-blue-600">
            support@stridez.io
          </a>
          .
        </li>
        <li>
          Correction of Personal Information: If you believe that the personal
          information, we hold about you is inaccurate, incomplete, or out of
          date, you have the right to request its correction. You can update
          your information by logging into your account or contacting us at the
          provided email address.
        </li>
        <li>
          Consent Withdrawal: If you provided consent for the collection, use,
          or disclosure of your personal information, you have the right to
          withdraw that consent at any time. However, withdrawing consent may
          impact your ability to use certain features of the platform.
        </li>
        <li>
          Complaints and Inquiries: If you have concerns about how your personal
          information is being handled or if you believe your privacy rights
          have been violated, you can lodge a complaint with the Office of the
          Australian Information Commissioner (OAIC). Additionally, you may
          contact us at{" "}
          <a href="mailto:support@stridez.io" className="text-blue-600">
            support@stridez.io
          </a>{" "}
          to address your inquiries and concerns.
        </li>
        <li>
          Anonymity and Pseudonymity: Australian users have the option to use a
          pseudonym or remain anonymous when interacting with our platform,
          provided that it is lawful and practical to do so. However, certain
          interactions may require accurate identification for contractual or
          legal reasons.
        </li>
        <li>
          Unsolicited Personal Information: If we receive unsolicited personal
          information that we did not request, we will take reasonable steps to
          determine whether it could have been collected lawfully. If not, we
          will delete or de-identify the information as appropriate.
        </li>
        <li>
          Cross-Border Data Transfers: Stridez may transfer personal information
          to countries outside of Australia. We take measures to ensure that
          these transfers comply with Australian privacy laws and the safeguards
          outlined in this Privacy Policy.
        </li>
        <li>
          Privacy Complaints: If you have concerns about our compliance with the
          Privacy Act 1988 or the handling of your personal information, please
          contact us at{" "}
          <a href="mailto:support@stridez.io" className="text-blue-600">
            support@stridez.io
          </a>
          . We will respond promptly to address your inquiries and concerns.
        </li>
      </ol>
      <p className="mb-4">
        We respect the rights and privacy of our Australian users and are
        committed to upholding the principles of the{" "}
        <strong>Privacy Act 1988</strong>. If you have any questions or wish to
        exercise your rights under the Act, please contact us using the provided
        email address.
      </p>

      <h2 className="mt-6 mb-4 text-2xl font-bold">
        11. Rights of Cyprus, EU, EEA, Switzerland, and UK users
      </h2>
      <p className="mb-4">
        This section of the Policy supplements the other provisions of this
        Privacy Policy, and applies to you if you are in the Cyprus, EU, the
        European Economic Area (EEA) or Switzerland or UK. Cyprus is a member of
        the European Union and its data privacy law is regulated by the EU's
        General Data Protection Regulation (GDPR) which came into effect in May
        2018.
      </p>
      <h3 className="mt-4 mb-2 text-xl font-bold">
        ALL YOUR USER INFORMATION WILL BE COLLECTED, STORED, PROCESSED AND
        SHARED STRICTLY IN ACCORDANCE, IN LINE AND FULL COMPLIANCE WITH
        REGULATION (EU) 2016/679 (SIMPLY CALLED "GDPR"), DIRECTIVE 2002/58/EC
        (SIMPLY CALLED "E-PRIVACY DIRECTIVE, 2002") OF THE EUROPEAN PARLIAMENT
        AND OF THE COUNCIL, AND UK'S DATA PROTECTION ACT 2018 (HERINAFTER
        COLLECTIVELY REFERRED TO AS THE "UK GDPR").
      </h3>
      <p className="mb-4">
        <span className="underline">
          For the purposes of GDPR, your <strong>DATA CONTROLLER</strong> is:
        </span>
      </p>
      <p className="mb-4">
        <strong>Company Name:</strong> Modular Web Ltd
      </p>
      <p className="mb-4">
        <strong>Company Registration Number:</strong> 14065707
      </p>
      <p className="mb-4">
        <strong>Registered Office:</strong> 71-75 Shelton Street, Covent Garden,
        London, United Kingdom, WC2H 9JQ
      </p>
      <p className="mb-4">
        Under applicable GDPR, you have the following rights in respect of your
        personal information:
      </p>
      <ul className="mb-4 list-disc list-inside">
        <li>
          <strong>Right to obtain information</strong>: to obtain information
          about how and on what basis your personal information is processed and
          to obtain a copy;
        </li>
        <li>
          <strong>Right to rectification:</strong> You have the right to have
          any incomplete or inaccurate information we hold about you rectified
          and corrected.
        </li>
        <li>
          <strong>Right of Erasure:</strong> to erase your personal information
          in limited circumstances where (a) you believe that it is no longer
          necessary for us to hold your personal information; (b) we are
          processing your personal information on the basis of legitimate
          interests and you object to such processing, and we cannot demonstrate
          an overriding legitimate ground for the processing; (c) where you have
          provided your personal information to us with your consent and you
          wish to withdraw your consent and there is no other ground under which
          we can process your personal information; and (d) where you believe
          the personal information we hold about you is being unlawfully
          processed by us;
        </li>
        <li>
          <strong>Right of restriction:</strong> to restrict processing of your
          personal information where: (a) the accuracy of the personal
          information is contested; (b) the processing is unlawful but you
          object to the erasure of the personal information; (c) we no longer
          require the personal information for the purposes for which it was
          collected, but it is required for the establishment, exercise or
          defense of a legal claim or (d) you have objected to us processing
          your personal information based on our legitimate interests and we are
          considering your objection;
        </li>
        <li>
          <strong>Right to object:</strong> to object to decisions which are
          based solely on automated processing or profiling;
        </li>
        <li>
          <strong>Right to ask for a copy:</strong> where you have provided your
          personal information to us with your consent, to ask us for a copy of
          this data in a structured, machine-readable format and to ask us to
          share (port) this data to another data controller; or to obtain a copy
          of or access to safeguards under which your personal information is
          transferred outside of the EEA.
        </li>
        <li>
          <strong>Right to withdraw your consent.</strong> You have the right to
          withdraw your consent on using your personal data. If you withdraw
          your consent, we may not be able to provide you with access to certain
          specific functionalities of our services.
        </li>
        <li>
          <strong>Request the transfer of your Personal Data.</strong> We will
          provide to you, or to a third-party you have chosen, your personal
          data in a structured, commonly used, machine-readable format. Please
          note that this right only applies to automated information which you
          initially provided consent for us to use or where we used the
          information to perform a contract with you.
        </li>
      </ul>
      <h3 className="mt-4 mb-2 text-xl font-bold">
        Under certain circumstances, you may have the right to object, on
        grounds relating to your particular situation, to the processing of your
        personal data by us and we may be required to no longer process your
        personal data. Moreover, if your personal data is processed for direct
        marketing purposes, you have the right to object at any time to the
        processing of personal data concerning you for such marketing, which
        includes profiling to the extent that it is related to such direct
        marketing. In this case your personal data will no longer be processed
        for such purposes by us.
      </h3>
      <p className="mb-4">
        In addition to the above, you have the right to lodge a complaint with a
        supervisory authority for data protection. Please note that the right of
        access and the right to erasure do not constitute absolute rights and
        the interests of other individuals may restrict your right of access or
        erase in accordance with local laws.
      </p>
      <p className="mb-4">
        We will ask you for additional data to confirm your identity and for
        security purposes, before disclosing data requested by you. We reserve
        the right to charge a fee where permitted by law. We will decline to
        process requests that jeopardize the privacy of others, are extremely
        impractical, or would cause us to take any action that is not
        permissible under applicable laws. Additionally, as permitted by
        applicable laws, we will retain where necessary certain personal
        information for a limited period of time for record-keeping, accounting
        and fraud prevention purposes.
      </p>
      <p className="mb-4">
        <strong>
          To make requests under this Section, please contact us at{" "}
          <a href="mailto:support@stridez.io" className="text-blue-600">
            support@stridez.io
          </a>
          .
        </strong>
      </p>

      <h2 className="mt-6 mb-4 text-2xl font-bold">
        12. California Resident Rights
      </h2>
      <p className="mb-4">
        This section of the Policy applies to you, if you are a California
        resident, as per <strong>California Privacy Rights Act, 2020 (</strong>
        simply called{" "}
        <strong>
          "CPRA"), California Privacy Rights Act (CPRA), California Consumer
          Policy Act, 2018{" "}
        </strong>
        (simply called <strong>"CCPA"</strong>) and{" "}
        <strong>California Online Privacy Protection Act </strong>(simply called{" "}
        <strong>"COPPA"</strong>). This privacy notice section for California
        residents supplements the information contained in our Privacy Policy
        and it applies solely to all visitors, users, and others who reside in
        the State of California.
      </p>
      <p className="mb-4">
        <strong>"Shine the Light" and "Eraser" Laws: </strong>Residents of the
        State of California may request a list of all third parties to which we
        have disclosed certain information during the preceding year for those
        third parties' direct marketing purposes.
      </p>
      <p className="mb-4">
        <strong>
          California Consumer Privacy Act (CCPA)/California Privacy Rights Act
          (CPRA):{" "}
        </strong>
        The CCPA, as amended by the CPRA, provides California residents and/or
        their authorized agents with specific rights regarding the collection
        and processing of their personal information.
      </p>
      <p className="mb-4">
        <strong>Your Right to Know: </strong>California residents have the right
        to request that we disclose the following information to you about our
        collection and use of your personal information over the past twelve
        (12) months. We may ask you to provide certain information to identify
        yourself so that we may compare it with our records in order to verify
        your request. Upon verification, we will disclose to you:
      </p>
      <ul className="mb-4 list-disc list-inside">
        <li>
          The categories of personal information we have collected about you.
        </li>
        <li>
          The categories of sources for the personal information we have
          collected about you.
        </li>
        <li>
          The specific pieces of personal information we have collected about
          you.
        </li>
        <li>
          Our business or commercial purpose for collecting or "selling" your
          personal information as defined by the CCPA.
        </li>
        <li>
          The categories of third parties to whom we have sold or shared your
          personal information, if any, and the categories of personal
          information that we have shared with each third-party recipient.
        </li>
      </ul>
      <p className="mb-4">
        <strong>
          Your Right to Opt-Out of "Sale" or "Sharing" of Personal Information
        </strong>
        : California residents have the right to opt- out of the "sale" or
        "sharing" of their personal information as defined by the CCPA by
        contacting us at{" "}
        <a href="mailto:service@honestymeter.com" className="text-blue-600">
          service@honestymeter.com
        </a>
        .
      </p>
      <p className="mb-4">
        Please note that we do not knowingly "sell" the personal information of
        any individuals.
      </p>
      <p className="mb-4">
        If and where we are "sharing" your personal information with third
        parties for the purposes of cross-context behavioral advertising or
        profiling, you may opt-out of such sharing at any time by submitting a
        request at{" "}
        <a href="mailto:service@honestymeter.com" className="text-blue-600">
          service@honestymeter.com
        </a>
        .
      </p>
      <p className="mb-4">
        <strong>
          Your Right to Limit Use of Sensitive Personal Information
        </strong>
        : California residents may have the right to request that businesses
        limit the use of any sensitive personal information to those uses which
        are necessary to perform the Services or for other
        specifically-enumerated business purposes under the CCPA, as amended by
        the CPRA. Please note that we do not use sensitive personal information
        other than as necessary to perform the Services or as specifically
        permitted under the CCPA.
      </p>
      <p className="mb-4">
        <strong>Your Right to Delete: </strong>California residents have the
        right to request that we delete any of the personal information
        collected from you and retained by us, subject to certain exceptions. We
        may ask you to provide certain information to identify yourself so that
        we may compare it with our records in order to verify your request. Once
        your request is verified and we have determined that we are required to
        delete the requested personal information in accordance with the CCPA,
        we will delete, and direct our service providers to delete your personal
        information from their records. Your request to delete personal
        information that we have collected may be denied if we conclude it is
        necessary for us to retain such personal information under one or more
        of the exceptions listed in the CCPA.
      </p>
      <p className="mb-4">
        <strong>Your Right to Correct: </strong>Under the CCPA, as amended by
        the CPRA, California residents have the right to request that we correct
        any inaccurate personal information we maintain about you, taking into
        account the nature of the personal information and the purposes for
        which we are processing such personal information. We will use
        commercially reasonable efforts to correct such inaccurate personal
        information about you.
      </p>
      <p className="mb-4">
        <strong>Non-Discrimination: </strong>You will not receive any
        discriminatory treatment by us for the exercise of your privacy rights
        conferred by the CCPA.
      </p>

      <h3 className="mt-4 mb-2 text-xl font-bold">
        Categories of Personal Information Collected
      </h3>
      <p className="mb-4">
        We collect information that identifies, relates to, describes,
        references, is capable of being associated with, or could reasonably be
        linked, directly or indirectly, with a particular consumer or device.
        The following is a list of categories of personal information which we
        may collect or may have been collected from California residents within
        the last twelve (12) months.
      </p>
      <p className="mb-4">
        Please note that the categories and examples provided in the list below
        are those defined in the CCPA. This does not mean that all examples of
        that category of personal information were in fact collected by us, but
        reflects our good faith belief to the best of our knowledge that some of
        that information from the applicable category may be and may have been
        collected. For example, certain categories of personal information would
        only be collected if you provided such personal information directly to
        us.
      </p>

      <ul className="mb-4">
        <li>
          <h4 className="font-bold">Category A: Identifiers.</h4>
          <p>
            <strong>Examples: </strong>A real name, alias, postal address,
            unique personal identifier, online identifier, Internet Protocol
            address, email address, account name, driver's license number,
            passport number, or other similar identifiers.
          </p>
          <p>
            <strong>Collected: </strong>Yes.
          </p>
        </li>
        <li>
          <h4 className="font-bold">
            Category B: Personal information categories listed in the California
            Customer Records statute (Cal. Civ. Code § 1798.80(e)).
          </h4>
          <p>
            <strong>Examples: </strong>A name, signature, Social Security
            number, physical characteristics or description, address, telephone
            number, passport number, driver's license or state identification
            card number, insurance policy number, education, employment,
            employment history, bank account number, credit card number, debit
            card number, or any other financial information, medical
            information, or health insurance information. Some personal
            information included in this category may overlap with other
            categories.
          </p>
          <p>
            <strong>Collected: </strong>Yes.
          </p>
        </li>
        <li>
          <h4 className="font-bold">
            Category C: Protected classification characteristics under
            California or federal law.
          </h4>
          <p>
            <strong>Examples: </strong>Age (40 years or older), race, color,
            ancestry, national origin, citizenship, religion or creed, marital
            status, medical condition, physical or mental disability, sex
            (including gender, gender identity, gender expression, pregnancy or
            childbirth and related medical conditions), sexual orientation,
            veteran or military status, genetic information (including familial
            genetic information).
          </p>
          <p>
            <strong>Collected: </strong>Yes.
          </p>
        </li>
        <li>
          <h4 className="font-bold">Category D: Commercial information.</h4>
          <p>
            <strong>Examples: </strong>Records and history of products or
            services purchased or considered.
          </p>
          <p>
            <strong>Collected: </strong>Yes.
          </p>
        </li>
        <li>
          <h4 className="font-bold">Category E: Biometric information.</h4>
          <p>
            <strong>Examples: </strong>Genetic, physiological, behavioral, and
            biological characteristics, or activity patterns used to extract a
            template or other identifier or identifying information, such as,
            fingerprints, faceprints, and voiceprints, iris or retina scans,
            keystroke, gait, or other physical patterns, and sleep, health, or
            exercise data.
          </p>
          <p>
            <strong>Collected: </strong>No.
          </p>
        </li>
        <li>
          <h4 className="font-bold">
            Category F: Internet or other similar network activity.
          </h4>
          <p>
            <strong>Examples: </strong>Interaction with our Service or
            advertisement.
          </p>
          <p>
            <strong>Collected: </strong>Yes.
          </p>
        </li>
        <li>
          <h4 className="font-bold">Category G: Geolocation data.</h4>
          <p>
            <strong>Examples: </strong>Approximate physical location.
          </p>
          <p>
            <strong>Collected: </strong>No.
          </p>
        </li>
        <li>
          <h4 className="font-bold">Category H: Sensory data.</h4>
          <p>
            <strong>Examples: </strong>Audio, electronic, visual, thermal,
            olfactory, or similar information.
          </p>
          <p>
            <strong>Collected: </strong>No.
          </p>
        </li>
        <li>
          <h4 className="font-bold">
            Category I: Professional or employment-related information.
          </h4>
          <p>
            <strong>Examples: </strong>Current or past job history or
            performance evaluations.
          </p>
          <p>
            <strong>Collected: </strong>No.
          </p>
        </li>
        <li>
          <h4 className="font-bold">
            Category J: Non-public education information (per the Family
            Educational Rights and Privacy Act (20 U.S.C. Section 1232g, 34
            C.F.R. Part 99)).
          </h4>
          <p>
            <strong>Examples: </strong>Education records directly related to a
            student maintained by an educational institution or party acting on
            its behalf, such as grades, transcripts, class lists, student
            schedules, student identification codes, student financial
            information, or student disciplinary records.
          </p>
          <p>
            <strong>Collected: </strong>No.
          </p>
        </li>
        <li>
          <h4 className="font-bold">
            Category K: Inferences drawn from other personal information.
          </h4>
          <p>
            <strong>Examples: </strong>Profile reflecting a person's
            preferences, characteristics, psychological trends, predispositions,
            behavior, attitudes, intelligence, abilities, and aptitudes.
          </p>
          <p>
            <strong>Collected: </strong>No.
          </p>
        </li>
      </ul>

      <p className="mb-4">
        We use the personal information that we collect or receive for the
        business purposes as described above. We may disclose the above listed
        categories of personal information to third parties for business
        purposes as described above. As previously mentioned in this Policy, we
        do not "sell" (as such term is defined in the CCPA) personal
        information.
      </p>

      <h3 className="mt-4 mb-2 text-xl font-bold">
        You are entitled to the following specific rights under the CCPA and
        CPRA in relation to personal information related to you:
      </h3>
      <ul className="mb-4 list-disc list-inside">
        <li>
          You have a right to request that we will disclose certain information
          to you about our collection and use of personal information related to
          you over the past 12 months, including: (i) The categories of personal
          information that we collect about you; (ii)The categories of sources
          from which the personal information is collected; (iii) The purposes
          for collecting, using, or selling that personal information. (iv) The
          categories of personal information that we disclosed for a business
          purpose or sold, and the categories of third parties to whom we
          disclosed or sold that particular category of personal information.
          (v) The specific pieces of personal information that we have collected
          about you.
        </li>
        <li>
          You have a right to request that we delete personal information
          related to you that we collected from you under certain circumstances
          and exceptions.
        </li>
        <li>
          You also have a right not to be discriminated against for exercising
          your rights under the CCPA.
        </li>
        <li>
          You also have a right to submit your request via an authorized agent.
          If you use an authorized agent to submit a request to access or delete
          your personal information on your behalf, the authorized agent must:
          (1) be a person or business entity registered with the California
          Secretary of State to conduct business in California;
        </li>
      </ul>
      <p className="mb-4">
        (2) provide proof of such registration; and (3) provide documentation or
        other proof indicating that they are authorized to act on your behalf.
        We may also require you to verify your identity directly with us, and
        directly confirm with us that you provided the authorized agent
        permission to submit the request.
      </p>
      <ul className="mb-4 list-disc list-inside">
        <li>
          You have a right to opt-out of sale or sharing of Personal Information
          (<strong>"PI"</strong>) and limit the use or disclosure of your
          Sensitive Personal Information (<strong>"SPI"</strong>).
        </li>
        <li>
          You have a right to correction, where you can request to have your PI
          and SPI corrected if you find them to be inaccurate.
        </li>
        <li>
          You have a right to opt-out of automated decision making, where you
          can say no to your PI and SPI being used to make automated inferences,
          e.g. in profiling for targeted, behavioral advertisement online.
        </li>
        <li>
          You have a right to know about automated decision making, where you
          can request access to and knowledge about how automated decision
          technologies work and what their probable outcomes are.
        </li>
        <li>
          You have a right to limit use of sensitive personal information, where
          you can make businesses restrict their use of your SPI, particularly
          around third-party sharing.
        </li>
      </ul>
      <p className="mb-4">
        <span className="underline">
          <strong>To make such requests, please contact us at </strong>
        </span>
        <a href="mailto:support@stridez.io" className="text-blue-600">
          support@stridez.io
        </a>
      </p>
      <p className="mb-4">
        The above rights will only become exercisable by California residents if
        Stridez falls within the scope of CCPA and/or CRPA. Further, we will
        verify your request using the information associated with your account,
        including email address. Government identification may also be required.
      </p>
      <p className="mb-4">
        <span className="underline">
          <em>
            A request for access can be made by you only twice within a
            12-months period. Any disclosures that we provide will
          </em>
        </span>
        <em></em>
        <span className="underline">
          <em>
            only cover the 12-months period preceding receipt of your request.
            We do not charge a fee to process or respond to
          </em>
        </span>
        <em></em>
        <span className="underline">
          <em>
            your verifiable User request unless it is excessive, repetitive, or
            manifestly unfounded. If we determine that the request
          </em>
        </span>
        <em></em>
        <span className="underline">
          <em>
            warrants a fee, we will inform you of the reasons for such a
            decision and provide you with a cost estimate before
          </em>
        </span>
        <em></em>
        <span className="underline">
          <em>processing further your request.</em>
        </span>
      </p>

      <h2 className="mt-6 mb-4 text-2xl font-bold">
        13. Notice for Nevada Residents
      </h2>
      <p className="mb-4">
        Under Nevada law, certain Nevada residents may opt out of the sale of
        "personally identifiable information" for monetary consideration to a
        person for that person to license or sell such information to additional
        persons.
      </p>
      <p className="mb-4">
        <strong>"Personally identifiable information" </strong>includes first
        and last name, address, email address, phone number, social security
        number, or an identifier that allows a specific person to be contacted
        either physically or online.
      </p>
      <h3 className="mt-4 mb-2 text-xl font-bold">
        Please note, we do not sell your personal information to anyone.
      </h3>

      <h2 className="mt-6 mb-4 text-2xl font-bold">
        14. Rights of Users from other Jurisdictions
      </h2>
      <p className="mb-4">
        For the purposes of the applicable law, your Data Controller is:
      </p>
      <p className="mb-4">
        <strong>Company Name:</strong> Modular Web Ltd
      </p>
      <p className="mb-4">
        <strong>Company Registration Number:</strong> 14065707
      </p>
      <p className="mb-4">
        <strong>Registered Office:</strong> 71-75 Shelton Street, Covent Garden,
        London, United Kingdom, WC2H 9JQ
      </p>
      <p className="mb-4">
        Depending upon the laws of your jurisdiction, you may be eligible for
        some or all of the following rights in respect of your personal
        information:
      </p>
      <ol className="mb-4 list-decimal list-inside">
        <li>
          Right to obtain information: You may have a right to obtain
          information about how and on what basis your personal information is
          processed and to obtain a copy.
        </li>
        <li>
          Right to rectification: You may have the right to have any incomplete
          or inaccurate information we hold about you rectified and corrected.
        </li>
        <li>
          Right of Erasure: You may have the right to erase your personal
          information in limited circumstances where
        </li>
      </ol>
      <p className="mb-4">
        (a) you believe that it is no longer necessary for us to hold your
        personal information; (b) we are processing your personal information on
        the basis of legitimate interests and you object to such processing, and
        we cannot demonstrate an overriding legitimate ground for the
        processing; (c) where you have provided your personal information to us
        with your consent and you wish to withdraw your consent and there is no
        other ground under which we can process your personal information; and
        (d) where you believe the personal information we hold about you is
        being unlawfully processed by us.
      </p>
      <ol start="4" className="mb-4 list-decimal list-inside">
        <li>
          Right of restriction: You may have the right to restrict processing of
          your personal information where: (a) the accuracy of the personal
          information is contested; (b) the processing is unlawful but you
          object to the erasure of the personal information; (c) we no longer
          require the personal information for the purposes for which it was
          collected, but it is required for the establishment, exercise or
          defense of a legal claim or (d) you have objected to us processing
          your personal information based on our legitimate interests and we are
          considering your objection.
        </li>
        <li>
          Right to object: You may have the right to object to decisions which
          are based solely on automated processing or profiling.
        </li>
        <li>
          Right to ask for a copy: Where you have provided your personal
          information to us with your consent, you may have the right to ask us
          for a copy of this data in a structured, machine-readable format and
          to ask us to share (port) this data to another data controller; or to
          obtain a copy of or access to safeguards under which your personal
          information is transferred outside of your jurisdiction.
        </li>
        <li>
          Right to withdraw your consent. You may have the right to withdraw
          your consent on using your personal data. If you withdraw your
          consent, we may not be able to provide you with access to certain
          specific functionalities of our services.
        </li>
        <li>
          Request the transfer of your Personal Data. If you so have this right,
          we will provide to you, or to a third- party you have chosen, your
          personal data in a structured, commonly used, machine-readable format.
          Please note that this right may only apply to automated information
          which you initially provided consent for us to use or where we used
          the information to perform a contract with you.
        </li>
      </ol>
      <h3 className="mt-4 mb-2 text-xl font-bold">
        To make such requests, please contact us at{" "}
        <a href="mailto:support@stridez.io" className="text-blue-600">
          support@stridez.io
        </a>
        . Please note, we reserve the right to reject the request if you are not
        entitled to the right that you request to enforce.
      </h3>

      <h2 className="mt-6 mb-4 text-2xl font-bold">
        15. How do we respond to legal requests?
      </h2>
      <p className="mb-4">
        We may access, preserve, and share your information in response to a
        legal request (like a search warrant, court order or subpoena) if we
        have a good faith belief that the law requires us to do so. This may
        include responding to legal requests from law enforcement agencies,
        courts, tribunals, and government authorities. We may also access,
        preserve, and share information when we have a good faith belief it is
        necessary to: detect, prevent, and address fraud and other illegal
        activity; to protect ourselves, you and others, including as part of
        investigations; or to prevent death or imminent bodily harm. We also may
        retain information from accounts disabled for violations of our terms
        for at least a year to prevent repeat abuse or other violations of our
        terms.
      </p>

      <h2 className="mt-6 mb-4 text-2xl font-bold">16. Children Privacy</h2>
      <p className="mb-4">
        Protecting children's privacy is important to us. If you are under the
        age of 18, you must have verifiable consent of your parent or legal
        guardian in order to use the website or our App or our Services. We do
        not direct the App/website or our services to, nor do we knowingly
        collect any personal information from, such children. If we learn that a
        child has provided personally identifiable information to us, we will
        use reasonable efforts to remove such information from our database.{" "}
        <span>
          <strong>
            Please contact us at{" "}
            <a href="mailto:support@stridez.io" className="text-blue-600">
              support@stridez.io
            </a>{" "}
            if you believe we unknowingly collected information described in
            this Section.
          </strong>
        </span>
      </p>

      <h2 className="mt-6 mb-4 text-2xl font-bold">
        17. How can I withdraw my consent? (OPT-OUT)
      </h2>
      <p className="mb-4">
        If you change your mind, you may withdraw your consent provided under
        this Privacy Policy at any time by contacting us at{" "}
        <a href="mailto:support@stridez.io" className="text-blue-600">
          support@stridez.io
        </a>
        .
      </p>

      <h2 className="mt-6 mb-4 text-2xl font-bold">
        18. Governing law and Dispute Resolution
      </h2>
      <p className="mb-4">
        Unless provided by the relevant statute, rules, or directives applicable
        to the jurisdiction in which you reside, in case of any disputes,
        issues, claims or controversies arising out of or in relation to your
        use of the website. or our services, the governing law and dispute
        resolution mechanism as provided in the{" "}
        <strong>Terms of Service </strong>shall apply to this{" "}
        <strong>Privacy Policy </strong>as well.
      </p>

      <h2 className="mt-6 mb-4 text-2xl font-bold">
        19. Do you have questions or concerns about this Privacy Policy?
      </h2>
      <p className="mb-4">
        In the event you have any grievance regarding anything related to this
        Privacy Policy, Terms of Service, or with any content or service of
        Stridez, in that case you may freely write your concerns through your
        registered email to Grievance Officer/Designated Representative at
        below:
      </p>
      <ul className="mb-4 list-disc list-inside">
        <li>
          <strong>Name: </strong>Stefan Milosevic
        </li>
        <li>
          <strong>Email: </strong>
          <a href="mailto:stefan@stridez.io" className="text-blue-600">
            stefan@stridez.io
          </a>
        </li>
        <li>
          <strong>Postal: </strong>71-75 Shelton Street, Covent Garden, London,
          United Kingdom, WC2H 9JQ
        </li>
      </ul>

      <h2 className="mt-6 mb-4 text-2xl font-bold">
        20. Updates to this Policy
      </h2>
      <p className="mb-4">
        We may add to or change or update this <strong>Privacy Policy </strong>
        at any time, from time to time, entirely at our own discretion, with or
        without any prior written notice. You are responsible for checking this
        Policy periodically. Your use of the website or App or our Services
        after any amendments to this Policy shall constitute your acceptance to
        such amendments.
      </p>

      <h2 className="mt-6 mb-4 text-2xl font-bold">
        21. Welcoming of Suggestions
      </h2>
      <p className="mb-4">
        We welcome your comments regarding this Privacy Policy. Please write to
        us at{" "}
        <a href="mailto:support@stridez.io" className="text-blue-600">
          support@stridez.io
        </a>
        .
      </p>

      <p className="mt-6">
        Last updated on <strong>August 8, 2024</strong>
      </p>
    </div>
  );
};

export default Privacy;
