import { useState, useEffect } from 'react';

export const useMultipleInView = (elements, options) => {
  const [inViewItems, setInViewItems] = useState([]);

  useEffect(() => {
    if (!elements.current) return;

    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        const index = elements.current.indexOf(entry.target);
        if (entry.isIntersecting && index !== -1) {
          setInViewItems(prev => {
            if (prev.includes(index)) return prev;
            return [...prev, index];
          });
          
          if (options?.triggerOnce) {
            observer.unobserve(entry.target);
          }
        }
      });
    }, {
      threshold: options?.threshold || 0,
      rootMargin: options?.rootMargin || '100px',
    });

    elements.current.forEach(element => {
      if (element) observer.observe(element);
    });

    return () => observer.disconnect();
  }, [options]);

  return inViewItems;
}; 