import React from "react";
import SectionHeader from "./SectionHeader";
import BlogItem from "./BlogItem";

const BlogList = ({
  blogs,
  error,
  isLoading,
  limit,
  heading,
  subheading,
  className,
  filterUrl,
}) => {
  // Filter out the blog with the specified filterTitle
  const filteredBlogs = filterUrl
    ? blogs?.filter((blog) => blog.title !== filterUrl)
    : blogs;
  // Apply the limit to the filtered blogs
  const limitedBlogs = limit ? filteredBlogs?.slice(0, limit) : filteredBlogs;

  if (isLoading) {
    return;
  }

  if (error) {
    return;
  }

  return (
    <div className={className}>
      <SectionHeader
        heading={heading}
        subheading={subheading}
        className="mb-8"
      />
      <div className="grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 xl:grid-cols-3">
        {limitedBlogs?.map((blog) => (
          <BlogItem key={blog?.url} {...blog} />
        ))}
      </div>
    </div>
  );
};

export default BlogList;
