import React from "react";
import BackButton from "../../components/BackButton";

const ToS = () => {
  return (
    <div className="w-full max-w-screen-md px-4 pt-4 pb-12 rich-text mx-auto text-black bg-white md:pt-8 md:px-6 lg:px-8 xl:px-4 docs">
      <BackButton />
      <h1 className="mb-6 text-3xl font-bold text-black text-center">TERMS OF SERVICE</h1>
      <p>
        Welcome to <strong>Stridez,</strong>
      </p>
      <p>
        These <strong>Terms of Service</strong> (hereinafter referred to as the{" "}
        <strong>"Terms" </strong>or <strong>"Terms of Use" </strong>or{" "}
        <strong>"Terms &amp; Conditions"</strong>) shall govern the relationship
        between our company and you. This website and App are owned and operated
        by:
      </p>
      <p>
        <span className="">
          <strong>Company Name: </strong>
        </span>
        Modular Web Ltd
      </p>
      <p>
        <span className="">
          <strong>Company Registration Number: </strong>
        </span>
        14065707
      </p>
      <p>
        <span className="">
          <strong>Registered Office: </strong>
        </span>
        71-75 Shelton Street, Covent Garden, London, United Kingdom, WC2H 9JQ
        (here simply referred to as <strong>"Company" </strong>or{" "}
        <strong>"Stridez" </strong>
        or <strong>"us" </strong>or <strong>"our" </strong>or{" "}
        <strong>"we"</strong>).
      </p>
      <p>
        You are here simply referred as <strong>"you" </strong>or{" "}
        <strong>"your" </strong>
        or <strong>"user" </strong>or <strong>"users" </strong>or{" "}
        <strong>"customers"</strong>
      </p>
      <p>
        These Terms shall govern your use of{" "}
        <a
          href="https://stridez.io/"
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-600"
        >
          https://stridez.io/
        </a>
        , and/or its related mobile application, <strong>"Stridez"</strong>, as
        available on <strong>Google Play Store </strong>and{" "}
        <strong>Apple App Store </strong>
        (hereinafter referred to as the <strong>"website" </strong>or{" "}
        <strong>"webapp" </strong>
        or <strong>"Site" </strong>or <strong>"App" </strong>or{" "}
        <strong>"Platform"</strong>).
      </p>
      <p>
        Stridez is an all-in-one fitness platform, available on webapp and
        mobile app. It provides predefined workouts and meals, AI-customized
        plans based on user profiles, workout and sport tracking, fitness
        calculators, progress tracking with image support, educational content
        on fitness and nutrition, and a community engagement platform via a
        fitness Discord server. It integrates with Apple Health and Android
        Health, offering a comprehensive, user- friendly, and personalized
        fitness solution. (hereinafter referred to as the{" "}
        <strong>"Service"</strong>)
      </p>
      <p>
        Please read these Terms carefully, as these, along with our{" "}
        <strong>
          Cancellation &amp; Refund Policy, Community Guidelines,{" "}
        </strong>
        and <strong>Privacy Policy </strong>statement forms the entire agreement
        between you and Stridez. Your access to and use of the Services are
        conditioned on your acceptance of and compliance with these Terms. If
        you do not accept these Terms in its entirety, then you may not use the
        website, App, or avail any of our services.
      </p>

      <h2 className="mt-6 mb-2 text-xl font-bold">
        1. ACCEPTANCE OF THE TERMS OF SERVICE
      </h2>
      <p>
        By using our AI-generated content, including personalized workout and
        meal plans, you acknowledge that these recommendations are for
        informational purposes only and should not replace professional medical
        advice.
      </p>
      <p>
        By downloading or installing our App, or by accessing or using the App
        or our website, or by registering an account on our platform, or by
        engaging with our Services, or by clicking on a button or taking similar
        action to signify your affirmative acceptance of these Terms, you hereby
        represent that:
      </p>
      <ol>
        <li>
          You have read, understood, and agreed to be bound by these Terms and
          any future updates and additions to these Terms, as published from
          time to time at the website and App.
        </li>
        <li>
          You are of <strong>sound mind</strong>, at least of{" "}
          <strong>18 years </strong>in legal age, and otherwise competent to
          form a binding contract with us. If you are not of the age of
          majority, then you must have a valid consent of your parents / lawful
          guardian to use the Services. By using the Services, you represent and
          warrant that you meet all of the foregoing eligibility requirements.
        </li>
        <li>
          We must not have previously disabled your account for violation of law
          or any of our policies.
        </li>
        <li>
          You have read, understood, and consented to our{" "}
          <strong>
            Community Guidelines, Cancellation &amp; Refund Policy,{" "}
          </strong>
          as well as our <strong>Privacy Policy </strong>statement.
        </li>
      </ol>

      <h2 className="mt-6 mb-2 text-xl font-bold">
        2. ALLOWED USES OF THE PLATFORM AND SERVICES
      </h2>
      <ol>
        <li>
          <strong>Personal Use: </strong>You are granted a limited,
          non-exclusive, and non-transferable license to use the App for
          personal, non-commercial purposes. This license allows you to access
          and use the features and content provided by the App.
        </li>
        <li>
          <strong>Access to Predefined Workouts and Meal Plans: </strong>Users
          are authorized to view and use the predefined workouts and meal plans
          offered by Stridez. These resources are designed to support users in
          achieving their fitness goals and adhering to healthy dietary
          practices.
        </li>
        <li>
          <strong>Personalized Fitness and Nutrition Recommendations: </strong>
          Stridez provides users with the capability to receive personalized
          fitness and nutrition plans. These recommendations are tailored
          according to the individual's body metrics, lifestyle aspirations, and
          geographical location, aiming to offer a customized fitness
          experience.
        </li>
        <li>
          <strong>Workout and Sports Activity Tracking: </strong>The App enables
          users to track their workout and sports activities, facilitating a
          comprehensive overview of daily and long-term fitness achievements.
          This feature assists users in maintaining a record of their exercise
          routines and monitoring progress towards their fitness targets.
        </li>
        <li>
          <strong>Nutritional Intake and Diet Management</strong>: Users are
          permitted to log their daily food intake using the App's AI Macro
          Calculator. This functionality supports users in managing their
          dietary needs, tracking calorie and nutrient intake, and setting goals
          for nutritional intake based on their fitness objectives.
        </li>
        <li>
          <strong>Progress Monitoring and Visual Documentation: </strong>The App
          offers a progress tracking feature that allows users to document their
          fitness journey through weight logs, images, and other visual aids.
        </li>
        <li>
          <strong>Use of Health and Fitness Calculators</strong>: Stridez
          includes access to various health and fitness calculators, such as
          Body Mass Index (BMI), Total Daily Energy Expenditure (TDEE),
          macro-nutrient calculations, and calorie deficit estimations.
        </li>
        <li>
          <strong>Step Tracking Integration: </strong>The App integrates with
          Apple Health and Android Health platforms to enable step tracking.
          Users can monitor the number of steps taken daily as part of their
          overall fitness regimen. Please refer to our{" "}
          <strong>Privacy Policy </strong>for more information.
        </li>
        <li>
          <strong>
            Access to Educational Resources on Fitness and Nutrition
          </strong>
          : Stridez provides users with access to educational content covering a
          wide range of topics related to fitness, nutrition, and overall
          wellness. This content aims to inform and empower users with knowledge
          to support their health and fitness journeys.
        </li>
        <li>
          <strong>Community Engagement via Fitness Discord Server</strong>:
          Users are encouraged to engage with the Stridez community through a
          dedicated fitness Discord server. This offers a space for interaction,
          support, and exclusive content, enhancing the user experience through
          community involvement. All your interactions within the community must
          comply with our <strong>Community Guidelines </strong>posted on our
          website and App.
        </li>
        <li>
          <strong>Directions of Use: </strong>You must follow any and all
          guidelines or policies or directions of use as associated with each of
          the services on the platform.
        </li>
        <li>
          <strong>Personal Information: </strong>Whenever prompted, you must
          provide us with the correct, accurate, and updated information. These
          will be handled in accordance with the{" "}
          <strong>Privacy Policy </strong>statement posted on our website and
          App.
        </li>
        <li>
          <strong>Material: </strong>For the purposes of these Terms, "material"
          shall mean any text, video, graphics, content, software, platform,
          sound material, published on the website or App, whether a copyright
          of Stridez, its licensors or any third party.{" "}
          <strong>(Unless stated otherwise) </strong>You are not allowed to
          download or print the material, or extracts from it, in a systematic
          or regular manner or otherwise so as to create a database in
          electronic or paper form comprising all or part of the material
          appearing on the website/App. Unless stated otherwise, you must not
          reproduce any part of the website/App or the material or transmit it
          to or store it in any other website/App or disseminate any part of the
          material in any other form, unless we have indicated that you may do
          so. However, you are allowed to use the content so generated by you
          using our platform in such form and manner as allowed in these Terms.
        </li>
        <li>
          Users may upload progress photos and other content. By doing so, you
          grant Stridez a non-exclusive, royalty-free license to use this
          content for the purpose of providing our services. You retain
          ownership of your content and may request its deletion at any time.
        </li>
      </ol>

      <h2 className="mt-6 mb-2 text-xl font-bold">3. DISCORD COMMUNITY</h2>
      <p>
        Stridez provides a Discord server for community engagement. By
        participating in this community, you agree to abide by both these Terms
        of Service and Discord's Terms of Service. Our Community Guidelines,
        available on our website, also govern your behavior in this space.
        Stridez reserves the right to moderate content and remove users from the
        Discord server for violations of these terms.
      </p>

      <h2 className="mt-6 mb-2 text-xl font-bold">4. PROHIBITED USES</h2>
      <ol>
        <li>
          The App and its features are intended for personal, non-commercial use
          only. Any attempt to use the App or its content for commercial
          purposes without explicit permission from the Company is prohibited.
        </li>
        <li>
          Misusing account features, including the creation of fake profiles,
          impersonation of other individuals, or any attempt to bypass system
          restrictions, is prohibited.
        </li>
        <li>
          The use of Stridez for any illegal purposes or to promote illegal
          activities is strictly prohibited. This includes any actions that
          violate local, national, or international laws.
        </li>
        <li>
          Creating multiple accounts or engaging in any activity aimed at
          abusing promotional offers provided by Stridez is forbidden.
        </li>
        <li>
          Harassing, bullying, or posting hateful content towards other users or
          engaging in any form of cyberbullying through the App or related
          community channels is not allowed.
        </li>
        <li>
          Using the App in any manner that could interfere with, disrupt,
          negatively affect, or inhibit other users from fully enjoying the App,
          or that could damage, disable, overburden, or impair the functioning
          of the App in any manner, is forbidden.
        </li>
        <li>
          Posting or sharing personal information of others without their
          explicit consent is not allowed. This includes sharing of private
          communication, personal data, or images.
        </li>
        <li>
          You cannot breach or circumvent or attempt to break or circumvent our
          authentication and security processes and measures of our systems or
          networks.
        </li>
        <li>
          You cannot reverse engineer or try to reverse engineer any of the
          proprietary/ intellectual property aspects of, including but not
          limited to the services, software, our platforms, networks, systems,
          etc., wholly or in part.
        </li>
        <li>
          You must not misuse or interfere with the Services or website/App or
          try to access them using a method other than the interface and the
          instructions that we provide. You may use the Services only as
          permitted by law and as per the directions provided by us.
        </li>
        <li>
          You must not use our website/App in any way that causes, or may cause,
          damage to the website/App or impairment of the availability or
          accessibility of the website/App; or in any way which is unlawful,
          illegal, fraudulent, or harmful, or in connection with any unlawful,
          illegal, fraudulent, or harmful purpose or activity.
        </li>
        <li>
          You cannot access, use, or tamper with the non-public areas of any of
          our software, platforms, networks, or our systems.
        </li>
        <li>
          You must not use our website/App to copy, store, host, transmit, send,
          use, publish or distribute any material which consists of (or is
          linked to) any spyware, computer virus, trojan horse, worm, keystroke
          logger, rootkit, or other malicious computer software.
        </li>
        <li>
          You must not conduct any systematic or automated data collection
          activities (including without limitation scraping, data mining, data
          extraction and data harvesting) on or in relation to our website/App,
          without our express written consent.
        </li>
      </ol>

      <h2 className="mt-6 mb-2 text-xl font-bold">5. INTELLECTUAL PROPERTY</h2>
      <ol>
        <li>
          <strong>Ownership of Content and Services: </strong>All content
          featured or displayed on the Stridez App and website, including, but
          not limited to, text, graphics, photographs, images, moving images,
          sound, illustrations, software, calculators, algorithms, and all other
          materials, as well as the trademarks, logos, and service marks
          (collectively, <strong>"Content"</strong>), are owned by MODULAR WEB
          LTD, its licensors, or its content providers. The design, structure,
          selection, coordination, expression, "look and feel", and arrangement
          of such Content, contained on the App or website, are owned by the
          Company, and are protected by copyright, patent, and trademark laws,
          and various other intellectual property rights and unfair competition
          laws.
        </li>
        <li>
          <strong>Use of Stridez Content</strong>: The Content provided on
          Stridez is available to you for your information and personal use
          only. It is not allowed to be used, copied, reproduced, distributed,
          transmitted, broadcast, displayed, sold, licensed, or otherwise
          exploited for any other purposes whatsoever without the prior written
          consent of the respective owners. The Company reserves all rights not
          expressly granted in and to the App, website, and the Content.
        </li>
        <li>
          <strong>User-Generated Content: </strong>Users may generate content,
          including but not limited to, progress photos, comments, and other
          materials (collectively, <strong>"User Content"</strong>) while using
          the App. Users retain all rights to their User Content; however, by
          uploading, posting, or otherwise making available any User Content on
          or through the App, users grant the Company a worldwide,
          non-exclusive, royalty-free, transferable license to use, reproduce,
          distribute, display, and perform the User Content in connection with
          the App and the Company's (and its successors' and affiliates')
          business, including without limitation for promoting and
          redistributing part or all of the App (and derivative works thereof)
          in any media formats and through any media channels.
        </li>
        <li>
          <strong>Protection of Intellectual Property: </strong>The Company
          respects the intellectual property rights of others and expects users
          of the App to do the same. The Company may, in appropriate
          circumstances and at its discretion, disable or terminate the accounts
          of users who may be repeat infringers.
        </li>
        <li>
          <strong>User Feedback: </strong>Any feedback you provide to the
          Company will be considered non-confidential and non-proprietary. By
          providing any feedback, you grant Stridez and its affiliates a
          perpetual, worldwide, non-exclusive, royalty-free, transferable
          license to use, reproduce, modify, perform, display, distribute, and
          otherwise disclose to third parties any such material for any purpose.
        </li>
        <li>
          <strong>Protection of Intellectual Property</strong>: Stridez respects
          the intellectual property rights of others and asks users of our
          platforms to do the same. Stridez may, in appropriate circumstances
          and at its discretion, disable or terminate the accounts of users who
          may be repeat infringers of intellectual property rights of others. If
          you believe that your work has been copied in a way that constitutes
          copyright infringement, please report it to us at{" "}
          <a href="mailto:support@stridez.io" className="text-blue-600">
            support@stridez.io
          </a>
          .
        </li>
        <li>
          Educational content provided through the app, including eBooks and
          articles, is for personal use only. Reproduction or distribution of
          this content without express permission is prohibited.
        </li>
      </ol>

      <h2 className="mt-6 mb-2 text-xl font-bold">6. PRIVACY</h2>
      <p>
        In order to see what personal and non-personal information we collect
        and how we use or store or share the same, please refer to the detailed{" "}
        <strong>Privacy Policy </strong>statement available on our website and
        App.
      </p>
      <p>
        Our app integrates with Apple Health and Google Fit. Data shared with
        these platforms is subject to their respective privacy policies in
        addition to ours. We only access and share data necessary for the
        functioning of our services.
      </p>
      <p>
        We employ industry-standard security measures to protect your health and
        fitness data. This data is encrypted in transit and at rest. We do not
        sell your personal data to third parties.
      </p>
      <p>
        In compliance with data protection regulations, users have the right to
        request a copy of their personal data, have it corrected, or deleted. To
        exercise these rights, please contact our support team.
        support@stridez.io
      </p>

      <h2 className="mt-6 mb-2 text-xl font-bold">7. SUBSCRIPTION</h2>
      <p>Stridez offers the following subscription tiers:</p>
      <p>
        Premium: Offers advanced features and an ad-free experience for a
        monthly fee. Premium+: Provides all features including AI-personalized
        workouts and meals for a higher monthly fee. Lifetime: Grants access to
        all Premium+ features for a one-time payment.
      </p>
      <p>
        The 'Lifetime' subscription is valid for the duration of Stridez's
        operation. If Stridez ceases to operate, this subscription will no
        longer be valid.
      </p>
      <ol>
        <li>
          <strong>Subscription Tiers: </strong>Stridez offers several
          subscription tiers, including Free, Premium, Premium+, and Lifetime
          options. Each tier provides different levels of access to the features
          and services of the Stridez App. Details regarding the benefits and
          costs associated with each subscription tier are available within the
          App and on the Stridez website.
        </li>
        <li>
          <strong>Premium Subscriptions</strong>: Premium and Premium+
          subscriptions offer enhanced features and an ad-free experience for a
          monthly or annual fee. Prices and specific features available for each
          tier are detailed in the App and subject to change. The Lifetime
          subscription provides access to all features offered in the Premium+
          tier for a one-time payment, with no additional costs for the lifetime
          of the App. The subscription is valid for as long as Stridez continues
          to provide its services. Should Stridez cease operations or
          discontinue the App, the Lifetime subscription will no longer be
          valid, and no refunds or compensation will be provided.
        </li>
        <li>
          <strong>Payment and Renewal: </strong>Subscription fees are charged in
          advance on a monthly or annual basis, as per the user's selection, and
          are non-refundable except as provided in the{" "}
          <strong>Cancellation &amp; Refund Policy</strong>. Subscriptions will
          automatically renew at the end of each subscription period, unless the
          user cancels the subscription through their account settings before
          the end of the current subscription period.
        </li>
        <li>
          <strong>Price Changes</strong>: The Company reserves the right to
          adjust pricing for the subscriptions offered through the App at any
          time as we deem necessary. Any price changes will take effect
          following email notice to you or announcement through the App.
        </li>
        <li>
          <strong>Cancellation Policy</strong>: Users can cancel their
          subscriptions at any time through their account settings in the App or
          on the website. Cancellation will take effect at the end of the
          current billing cycle, allowing the user to retain access to the
          services until the end of the period. No refunds are provided for any
          remaining days in the current subscription period.
        </li>
        <li>
          <strong>Free Trial: </strong>Stridez offers a free trial period for
          new subscribers. Users will not be charged during the free trial
          period, and they can cancel at any time before the trial period
          expires to avoid being charged. If not cancelled, the subscription
          will automatically continue, and the payment method provided will be
          charged at the start of the first billing cycle following the end of
          the free trial period. During the free trial, users have access to all
          features of the App.
        </li>
      </ol>

      <h2 className="mt-6 mb-2 text-xl font-bold">
        8. GAMIFICATION AND REWARDS
      </h2>
      <p>
        Stridez employs a point system and leaderboards to enhance user
        engagement. Points are earned through activities specified in the app.
        These points may be used for in-app purchases or to maintain streaks.
        Points have no monetary value and may expire if your account is inactive
        for 12 consecutive months. Stridez reserves the right to modify the
        point system at any time.
      </p>

      <h2 className="mt-6 mb-2 text-xl font-bold">
        9. LINKS TO THIRD-PARTY PLATFORMS
      </h2>
      <p>
        The website/App might contain links to third-party website/Apps or
        services.{" "}
        <em>
          For example, the website/App works with third party payment service
          providers to provide you the most convenient payment options.
        </em>{" "}
        Such third-party links are not under the control of Stridez, and Stridez
        is not responsible for any third-party links. Stridez provides access to
        these third-party links only as a convenience to you, and does not
        review, approve, monitor, endorse, warrant, or make any representations
        with respect to third-party links. You shall use all third-party links
        at your own risk, and should apply a suitable level of caution and
        discretion in doing so. Whenever you click on such links, you may be
        taken to such a third- party website/App, and you get out of the
        jurisdiction of our website/App.{" "}
        <span className="underline">
          Therefore, you shall be governed by the legal policies of such
          third-party website/Apps and we suggest that you read those policies.
        </span>{" "}
        <strong>
          In case of any damage due to such action of third-party links or
          inaccuracy of content or wrong information, Stridez shall not be
          responsible.
        </strong>
      </p>

      <h2 className="mt-6 mb-2 text-xl font-bold">10. DISCLAIMER</h2>
      <p>By using Stridez, users acknowledge and agree to this Disclaimers:</p>
      <ol>
        <li>
          <strong>Medical Advice</strong>: Stridez provides fitness and
          nutrition information for educational and informational purposes only.
          It is not intended as, and should not be relied upon as, professional
          medical advice, diagnosis, or treatment. Always seek the guidance of
          your physician or another qualified health provider regarding any
          medical condition or before starting any new diet or exercise program.
        </li>
        <li>
          <strong>Physical Risks</strong>: Physical activity, including
          exercises recommended by Stridez, carries inherent risks. Users should
          proceed with caution and consider their physical limitations, health
          status, and the advice of medical professionals before undertaking any
          physical activity recommended by the App. The Company is not liable
          for injuries or health issues resulting from exercises or activities
          performed based on the App's recommendations.
        </li>
        <li>
          <strong>Accuracy of Information: </strong>While MODULAR WEB LTD
          strives to ensure that the information provided through the Stridez
          App and website is accurate and up to date, we do not guarantee the
          completeness, reliability, or accuracy of such information. The
          content may contain errors or omissions, and we expressly disclaim any
          liability for such inaccuracies or errors to the fullest extent
          permitted by law. We endeavor to provide accurate fitness tracking and
          health-related metrics. However, the Company does not guarantee the
          precision or reliability of this data. Users are advised to use this
          information as a general guide rather than an absolute measure.
          Decisions made based on app data, particularly those affecting fitness
          routines and health outcomes, are the sole responsibility of the user.
        </li>
        <li>
          <strong>Availability of the App and Services</strong>: The Company
          does not warrant that the Stridez App and its services will be
          available at all times or in all locations. We may experience downtime
          due to maintenance, updates, or unforeseen technical issues. Access to
          the App and its features may be restricted or unavailable during such
          periods without notice.
        </li>
        <li>
          <strong>Health and Fitness Guidance</strong>: The Stridez App provides
          health and fitness information for general informational purposes
          only. It is not intended as medical advice, diagnosis, or treatment.
          Users should consult with a healthcare professional before starting
          any new workout program, especially if they have pre-existing health
          conditions or concerns. The Company is not responsible for any health
          issues that may arise from the use of the App.
        </li>
        <li>
          <strong>Third-Party Links and Content: </strong>The Stridez App may
          contain links to third-party websites or resources. These links are
          provided for convenience only, and the inclusion of any link does not
          imply endorsement by the Company. We have no control over, and assume
          no responsibility for, the content, privacy policies, or practices of
          any third-party websites or services.
        </li>
        <li>
          <strong>User-Generated Content: </strong>Users may submit content to
          the App, including but not limited to, progress photos and comments.
          The Company does not endorse any user-generated content and expressly
          disclaims any and all liability in connection with such content. Users
          are responsible for their own content and the repercussions of posting
          or publishing it.
        </li>
        <li>
          <strong>Service Modifications and Price Changes</strong>: The Company
          reserves the right at any time to modify or discontinue, temporarily
          or permanently, the Service (or any part of it) with or without
          notice. Prices of all services are subject to change without notice.
          We shall not be liable to you or to any third-party for any
          modification, price change, suspension, or discontinuance of the
          Service.
        </li>
        <li>
          <strong>Subscriptions and Cancellations</strong>: The Stridez App
          offers subscription-based services. It is the user's responsibility to
          review the terms of the subscription, including renewal dates and
          cancellation policies. The Company is not liable for any disputes or
          issues related to subscription charges or cancellations.
        </li>
        <li>
          <strong>No Warranties: </strong>The Service is provided "as is" and
          "as available" without any representations or warranties, express or
          implied, including but not limited to implied warranties of
          merchantability, fitness for a particular purpose, or
          non-infringement. The Company does not warrant that the App or any of
          its services will function uninterrupted, securely, or be available at
          any particular time or location.
        </li>
      </ol>

      <h2 className="mt-6 mb-2 text-xl font-bold">
        11. LIMITATION OF LIABILITY
      </h2>
      <ol>
        <li>
          <strong>General Limitation</strong>: To the fullest extent permitted
          by law, MODULAR WEB LTD (<strong>"Company"</strong>), its directors,
          employees, partners, agents, suppliers, or affiliates shall not be
          liable for any indirect, incidental, special, consequential, or
          punitive damages, including without limitation, health issues,
          physical or mental harm, medical expense, loss of profits, data, use,
          goodwill, or other intangible losses, resulting from (i) your access
          to or use of or inability to access or use the Stridez App or
          services; (ii) any conduct or content of any third party on the
          Stridez App; (iii) any content obtained from the Stridez App; and (iv)
          unauthorized access, use, or alteration of your transmissions or
          content, whether based on warranty, contract, tort (including
          negligence), or any other legal theory, whether or not the Company has
          been informed of the possibility of such damage, and even if a remedy
          set forth herein is found to have failed of its essential purpose.
        </li>
        <li>
          <strong>Applicable Law Exceptions: </strong>Certain jurisdictions do
          not allow the exclusion or limitation of certain damages, so some or
          all of the exclusions and limitations in this section may not apply to
          you if you are located in such jurisdictions. In such cases, Stridez's
          liability will be limited to the greatest extent permitted by law.
        </li>
      </ol>

      <h2 className="mt-6 mb-2 text-xl font-bold">12. LEGAL ACTION</h2>
      <p>
        If you are found to be degrading, tarnishing, maligning the image,
        goodwill, or reputation of <strong>Stridez </strong>by spreading hate,
        insulting, false, fake reviews or engaging in mala fide actions against
        the above, strong legal actions will be taken immediately.
      </p>

      <h2 className="mt-6 mb-2 text-xl font-bold">13. INDEMNIFICATION</h2>
      <p>
        You acknowledge to defend, indemnify, and hold Stridez, its affiliates,
        subsidiaries, directors, officers, employees, agents, partners, and any
        other licensors (hereinafter referred to as{" "}
        <strong>"Indemnified Party"</strong>) harmless from and against any
        claim, disputes, fine, liability, demand, or expense, including
        reasonable legal counsel's fee, made by a third party, relating to, or
        arising from:
      </p>
      <ol>
        <li>Your violation of ours or any third-party right;</li>
        <li>Your wrongful or improper use of our services, or platform;</li>
        <li>Your violation of any applicable laws, rules, or regulations;</li>
        <li>
          Your violation of these Terms or any other policy of ours as
          associated with our services;
        </li>
        <li>
          The indemnifications set forth above will survive the termination or
          expiration of these Terms and/or your use of our services.
        </li>
      </ol>

      <h2 className="mt-6 mb-2 text-xl font-bold">
        14. GOVERNING LAW AND DISPUTE RESOLUTION
      </h2>
      <ol>
        <li>
          <strong>Governing Law: </strong>The Terms and any dispute arising from
          the same will be governed by applicable laws of the{" "}
          <strong>United Kingdom (UK)</strong>, except for its conflict of law
          provisions.
        </li>
        <li>
          <strong>Exclusive Jurisdiction</strong>: All disputes must first be
          attempted to be resolved amicably, failing which, such controversy,
          conflict or dispute shall be finally settled by bringing it
          exclusively before the appropriate courts or tribunals located in{" "}
          <strong>London, United Kingdom.</strong>
        </li>
        <li>
          <strong>Statutory Rights: </strong>This Section shall in no way
          override the rights available to you under the applicable laws of your
          jurisdiction.
        </li>
      </ol>

      <h2 className="mt-6 mb-2 text-xl font-bold">15. NOTICES</h2>
      <p>
        When you use the website/App or send emails to Stridez, you are
        communicating with us electronically. You consent to receive physically
        or electronically any communications related to your use of this
        website/App. Stridez will communicate with you by email or by posting
        notices on this website/App. You agree that all agreements, notices,
        disclosures, and other communications that are provided to you
        electronically satisfy any legal requirement that such communications be
        in writing. We may give notice by means of a general notice via
        electronic mail to your email address as available with us. If you want
        to give a notice to us, you can do so by dropping an electronic mail to{" "}
        <a href="mailto:support@stridez.io" className="text-blue-600">
          support@stridez.io
        </a>
        .
      </p>

      <h2 className="mt-6 mb-2 text-xl font-bold">16. MISCELLANEOUS</h2>
      <ol>
        <li>
          <strong>Independent Legal Advice: </strong>It is your obligation to
          obtain independent legal advice at your own expense to ensure you
          understand the provisions of these Terms.
        </li>
        <li>
          <strong>Headings: </strong>The section headings are for convenience
          only and shall not control or affect the meaning or construction of
          any provision of these Terms.
        </li>
        <li>
          <strong>Breach: </strong>In case of any breach or threatened breach to
          the provisions of these Terms, we reserve the right to suspend your
          access to our platform or our Services, at our sole discretion. In
          such cases, you will not be entitled to any refund or compensation.
        </li>
        <li>
          <strong>Severability: </strong>If any provision of these Terms is held
          to be unenforceable or invalid, such provision will be changed and
          interpreted to accomplish the objectives of such provision to the
          greatest extent possible under applicable law and the remaining
          provisions of these Terms will continue in full force and effect.
        </li>
        <li>
          <strong>Waiver: </strong>Our failure to insist on or enforce strict
          performance of these Terms of Service shall not be construed as a
          waiver by us of any provision or any right that we have to enforce
          these Terms and nor shall any course of conduct between Company and
          you or any other party be deemed to modify any provision of these
          Terms.
        </li>
        <li>
          <strong>Survival: </strong>Notwithstanding any other provisions of
          these Terms of Service, or any general legal principles to the
          contrary, any provision of these Terms of Service that imposes or
          contemplates continuing obligations on either party shall survive the
          expiration or termination of these Terms of Service, for any reason
          whatsoever.
        </li>
        <li>
          <strong>No Third-Party Beneficiaries: </strong>Except as otherwise
          expressly provided in these Terms of Service, there shall be no
          third-party beneficiaries to these Terms of Service.
        </li>
        <li>
          <strong>No Assignment: </strong>Users may not assign these Terms of
          Service (or any rights, benefits, or obligations hereunder) by
          operation of law or otherwise without the prior written consent of
          Stridez, which may be withheld at Stridez's sole discretion. Any
          attempted assignment that does not comply with these Terms of Service
          shall be null and void.
        </li>
        <li>
          <strong>Entire Agreement: </strong>The{" "}
          <strong>
            Terms, Community Guidelines, Cancellation &amp; Refund Policy,{" "}
          </strong>
          and <strong>Privacy Policy</strong>, and any additional terms and
          conditions incorporated herein or referred to herein constitute the
          entire agreement between Stridez and you, relating to the subject
          matter hereof, and supersedes any prior understanding or agreements
          (whether oral or written) regarding the subject matter, and may not be
          amended or modified except in writing or by making such amendments or
          modifications available on our website/App.
        </li>
        <li>
          <strong>Force Majeure: </strong>Stridez and its third-party service
          providers will be excused from performance for any period during
          which, and to the extent that, such party or any subcontractor is
          prevented from performing any obligation or service, in whole or in
          part, as a result of causes beyond its reasonable control, and without
          its fault or negligence, including without limitation, acts of God,
          communication line failures, technical issues, bugs, errors, power
          failures and/or similar inabilities.
        </li>
        <li>
          <strong>Updates to these Terms: </strong>Please note, these Terms may
          be updated from time to time to reflect changes to our practices,
          market realities, or for other operational, legal, or regulatory
          reasons. Changes are effective immediately upon posting to our Website
          or within the App. Your continued use of our Services following the
          posting of revised Terms means that you accept and agree to the
          changes. We encourage you to frequently review the Terms to ensure you
          understand the terms and conditions that apply to your use of our
          Services.
        </li>
      </ol>

      <h2 className="mt-6 mb-2 text-xl font-bold">
        17. GRIEVANCE OFFICER/DESIGNATED REPRESENTATIVE
      </h2>
      <p>
        In the event you have any grievance regarding anything related to these{" "}
        <strong>Terms or Cancellation </strong>or{" "}
        <strong>Cancellation &amp; Refund Policy </strong>or{" "}
        <strong>Privacy Policy</strong>, or with any content or service of
        Stridez, in that case you may freely write your concerns to the
        Grievance Officer/Designated Officer at below:
      </p>
      <ul>
        <li>
          <strong>Name: </strong>Stefan Milosevic
        </li>
        <li>
          <strong>Email: </strong>
          <a href="mailto:stefan@stridez.io" className="text-blue-600">
            stefan@stridez.io
          </a>
        </li>
        <li>
          <strong>Postal: </strong>71-75 Shelton Street, Covent Garden, London,
          United Kingdom, WC2H 9JQ
        </li>
      </ul>

      <h2 className="mt-6 mb-2 text-xl font-bold">
        18. FEEDBACK AND INFORMATION
      </h2>
      <p>
        We welcome your questions or comments regarding the Terms. You can write
        to us via email:{" "}
        <a href="mailto:support@stridez.io" className="text-blue-600">
          support@stridez.io
        </a>
        .
      </p>

      <p>
        Last updated on <strong>August 8, 2024.</strong>
      </p>
    </div>
  );
};

export default ToS;
